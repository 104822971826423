import { Box, Button, Divider, Grid, IconButton, makeStyles, Paper, TextField, Typography } from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import CreateNewFolderOutlinedIcon from '@material-ui/icons/CreateNewFolderOutlined';
import FilterListIcon from '@material-ui/icons/FilterList';
import SearchIcon from '@material-ui/icons/Search';
import SettingsIcon from '@material-ui/icons/Settings';
import ShareIcon from '@material-ui/icons/Share';
import React, { useContext, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import CustomBreadcrumbs from '../CustomBreadcrumbs';
import ModalDepartmentNewItem from '../ModalDepartmentNewItem';
import { AuthContext } from '../../context/Auth/AuthContext';
import { toast } from 'react-toastify';
import { useParams, useHistory } from 'react-router-dom';

const useStyles = makeStyles(theme => ({
    customContainer: {
        padding: '12px 24px',
    },
    customContainerButtons: {
        padding: '12px 24px',
        [theme.breakpoints.down('sm')]: {
            gap: 10
        },
    },
    customIconConfig: {
        display: 'flex',
        alignItems: 'center',
        padding: 0
    },
    customButton: {
        height: 40,
        marginRight: 16
    },
    customButtomRemoveMargin: {
        [theme.breakpoints.down('xs')]: {
            marginRight: 0
        },
    },
    customTextFieldBox: {
        display: 'flex',
        alignItems: 'flex-end',
        gap: 8
    },
    customTextFieldBoxCircle: {
        color: 'action.active',
        mr: 1,
        my: 0.5
    },
    customDivider: {
        marginBlock: 10,
        [theme.breakpoints.down('sm')]: {
            display: 'none',
        },
    },
    workSpaceHeader: {
        fontWeight: 400,
        fontSize: '24px'
    },
    hiddenOnMd: {
        [theme.breakpoints.down('sm')]: {
            display: 'none',
        },
    },
    noWrape: {
        flexWrap: 'nowrap'
    },
    customContainerLeft: {
        [theme.breakpoints.down('sm')]: {
            justifyContent: 'flex-start',
        },
    },
    removeNamesFromButtons: {
        [theme.breakpoints.down('xs')]: {
            display: 'none',
        },
    }
}));


const HeaderDepartment = ({ currentDepartmentData }) => {

    const classes = useStyles();

    const [openModal, setOpenModal] = useState(false);

    const [typeOfModal, setTypeOfModal] = useState("");

    function handleOpenModal(typeOfModal) {
       
        if (typeOfModal === "Item") {
            setOpenModal(true);
            setTypeOfModal("Item");
            return;
        }

        setOpenModal(true);
        setTypeOfModal("Folder");
    }

    const history = useHistory();
    const { user } = useContext(AuthContext);


    useEffect(() => {
      if (user && (!user.super && user.profile !== "admin")) {
        // console.log(user.ascview_usuario.ativo)
        if ((user.ascview_usuario && user.ascview_usuario.ativo !== 'Y')) {
          toast.error("Você não possui permissão para acessar essa página! Estamos lhe redirecionando.");
          setTimeout(() => {
            history.push(`/login`); // Redirecionar para a página de tickets ou outra página adequada
          }, 1000);
        }else if (!user.ascview_usuario){
          toast.error("Você não possui permissão para acessar essa página! Estamos lhe redirecionando.");
          setTimeout(() => {
            history.push(`/login`); // Redirecionar para a página de tickets ou outra página adequada
          }, 1000);
        }
      }
      
    }, [user, history]);

    
    return (
        <Paper elevation={3} square>
            <Grid container className={classes.customContainer} >
                <Grid container item xs={6} alignItems='center'>
                    <Grid item>
                        <Typography variant='body1' component='h2' className={classes.workSpaceHeader} color='textPrimary'>
                            {currentDepartmentData ? `${currentDepartmentData.name.toUpperCase()}` : "DEPARTAMENTOS"}
                            <CustomBreadcrumbs />
                        </Typography>
                    </Grid>
                </Grid>
                {(user.ascview_usuario && user.ascview_usuario.ativo == 'Y' && user.ascview_usuario.profile == 'admin' && (

                <Grid container item spacing={1} xs={6} justifyContent='flex-end' alignItems='center'>
                <Button component={Link} to="/departments-settings">
                    <Grid item className={classes.customIconConfig}>
                        
                            <IconButton aria-label='config'>
                                <SettingsIcon color='action' style={{ fontSize: 25 }} />
                            </IconButton>
                    </Grid>
                    <Grid item className={classes.hiddenOnMd}>
                    <Typography variant='body1' component='h3' color='textPrimary' sx={{ textTransform: 'none' }}>
    
                </Typography>

                    </Grid>
                </Button>

                </Grid>
                ))}
                
            </Grid>

            <Divider />
            {(user.ascview_usuario && user.ascview_usuario.ativo == 'Y' && user.ascview_usuario.profile == 'admin' && (
           <Grid container className={classes.customContainerButtons} alignItems='center'>
           <Grid item md={6} sm={12} >
               <Grid container alignItems='center' className={classes.noWrape}>
                   <Grid>
                       <Button
                           variant="contained"
                           color="default"
                           startIcon={<AddIcon />}
                           className={classes.customButton}
                           onClick={() => handleOpenModal("Item")}
                       >
                           <span>Novo Item</span>
                       </Button>
                   </Grid>

                   <Grid>
                       <Button
                           color="default"
                           className={classes.customButton}
                           startIcon={<CreateNewFolderOutlinedIcon />}
                           onClick={() => handleOpenModal("Folder")}
                       >
                           <span className={classes.removeNamesFromButtons}>Nova Pasta</span>
                       </Button>
                   </Grid>

                   {/* <Grid>
                       <Button
                           color="default"
                           className={classes.customButton}
                           startIcon={<PublishRoundedIcon />}
                       >
                           <span className={classes.removeNamesFromButtons}> Botao 3 </span>
                       </Button>
                   </Grid> */}

               </Grid>
           </Grid>

               {/*                <Grid container item md={6} sm={12} >
<Grid container item justifyContent='flex-end' alignItems='center' spacing={3} className={`${classes.noWrape} ${classes.customContainerLeft}`}>
                   <Grid item>
                       <Box className={classes.customTextFieldBox}>
                           <SearchIcon />
                           <TextField id="input-with-sx" label="Pesquisa" variant="standard" />
                       </Box>
                   </Grid>

                   <Grid item>
                       <Button
                           variant="contained"
                           color="default"
                           startIcon={<FilterListIcon />}
                           className={`${classes.customButton} ${classes.customButtomRemoveMargin}`}
                       >
                           Filtrar
                       </Button>
                   </Grid>

                   <Divider orientation='vertical' flexItem variant='middle' className={classes.customDivider} />

                   <Grid item>
                       <IconButton aria-label="share">
                           <ShareIcon />
                       </IconButton>
                   </Grid>
               </Grid>
           </Grid> */}
       </Grid>

            ))}
 
            <ModalDepartmentNewItem
                openModal={openModal}
                setOpenModal={setOpenModal}
                typeOfModal={typeOfModal}
                token={currentDepartmentData.token}
                parentId={currentDepartmentData.parentId}
            />

        </Paper >
    )
}

export default HeaderDepartment;