import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Modal from '@mui/material/Modal';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import React, { useContext, useState, useEffect } from 'react';
import { AuthContext } from '../../context/Auth/AuthContext';
import api from '../../services/api';
import { toast } from 'react-toastify';

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: { xs: '90%', sm: 800 },
    bgcolor: 'background.paper',
    border: 'none',
    boxShadow: 24,
    p: 4,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    gap: 3,
    borderRadius: '12px',
};

export default function ModalRowTableDepartmentsPermission({ openModalPermission, setOpenModalPermission, rowIdCell, fileType, object }) {
    const { user } = useContext(AuthContext);
    const [email, setEmail] = useState('');
    const [permittedUsers, setPermittedUsers] = useState([]);

    const fetchPermittedUsers = async () => {
        try {
            setPermittedUsers([])
            const response = await api.get(`/permissoes-resumo/${object.id}/${object.tipo}/${user.companyId}`);
            setPermittedUsers([response.data]);
        } catch (error) {
            console.error('Erro ao buscar usuários com permissão:', error);
            toast.warning('Não foi identificado nenhum usuário com permissão.');
        }
    };

    useEffect(() => {
        if (openModalPermission) {
            fetchPermittedUsers();
        }
    }, [openModalPermission]);

    const handlePermission = async () => {
        try {
            const payload = {
                p_usuario_email: email,
                p_relatorio_id: object.tipo === 'relatorio' ? object.id : null,
                p_pasta_id: object.tipo === 'pasta' ? object.id : null,
                p_tipo_acesso: 'ver',
            };
            console.log(payload)
            const response = await api.post('/permissao/gerenciar/', payload);
            console.log(response)
            toast.success('Permissão concedida com sucesso');
            fetchPermittedUsers();
            setEmail('');
        } catch (error) {
            if(error.status == '404'){
                toast.error('Usuário não foi/não está cadastrado no Ascview.')
            }
            console.error('Erro ao conceder permissão:', error);
            toast.error('Erro ao conceder permissão');
        }
    };

    const handleRemovePermission = async (usuarioId) => {
        try {

            const payload = {
                p_usuario_email: usuarioId,
                p_relatorio_id: object.tipo === 'relatorio' ? object.id : null,
                p_pasta_id: object.tipo === 'pasta' ? object.id : null
            };
            console.log(payload)
            await api.delete(`/permissao/remover/`, {data: payload});
            toast.success('Permissão removida com sucesso');
            fetchPermittedUsers();
        } catch (error) {
            console.error('Erro ao remover permissão:', error);
            toast.error('Erro ao remover permissão');
        }
    };

    return (
        <div>
            <Modal
                open={openModalPermission}
                onClose={() => setOpenModalPermission(false)}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={style}>
                    <Typography id="modal-modal-title" variant="h5" component="h2" sx={{ textAlign: 'center', fontWeight: 'bold' }}>
                        {`Conceder permissão ${fileType === 'relatorio' ? 'para este relatório' : 'para esta pasta'}?`}
                    </Typography>

                    <TextField
                        label="E-mail do usuário"
                        variant="outlined"
                        fullWidth
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                        sx={{ mb: 2 }}
                    />

                    <Button
                        onClick={handlePermission}
                        variant="contained"
                        color="primary"
                        sx={{
                            width: '60%',
                            fontSize: { xs: '0.875rem', sm: '1rem' },
                            padding: '10px',
                            backgroundColor: '#1976d2',
                            '&:hover': {
                                backgroundColor: '#1565c0',
                            },
                        }}
                    >
                        Conceder Permissão
                    </Button>

                    <Typography variant="h6" component="h2" sx={{ textAlign: 'center', mt: 3, fontWeight: '500' }}>
                        Usuários com permissão:
                    </Typography>
                    <Box sx={{ width: '100%', display: 'flex', flexDirection: 'column', alignItems: 'center', gap: 1 }}>
                        {permittedUsers.map((user) => (
                            <Box
                                key={user.id}
                                sx={{
                                    display: 'flex',
                                    justifyContent: 'space-between',
                                    width: '100%',
                                    bgcolor: 'grey.100',
                                    padding: '10px',
                                    borderRadius: '8px',
                                    boxShadow: 1,
                                }}
                            >
                                <Typography>{user.ascview_usuario.email}</Typography>
                                <Button
                                    onClick={() => handleRemovePermission(user.ascview_usuario.email)}
                                    variant="outlined"
                                    color="error"
                                    sx={{
                                        minWidth: '36px',
                                        padding: '5px',
                                        fontSize: '0.8rem',
                                        borderRadius: '50%',
                                        border: 'none',
                                        '&:hover': {
                                            backgroundColor: '#ff1744',
                                            color: 'white',
                                        },
                                    }}
                                >
                                    X
                                </Button>
                            </Box>
                        ))}
                    </Box>

                    <Button
                        onClick={() => setOpenModalPermission(false)}
                        variant="contained"
                        sx={{
                            width: '50%',
                            mt: 2,
                            fontSize: { xs: '0.875rem', sm: '1rem' },
                            bgcolor: '#9e9e9e',
                            '&:hover': {
                                backgroundColor: '#757575',
                            },
                        }}
                    >
                        Cancelar
                    </Button>
                </Box>
            </Modal>
        </div>
    );
}
