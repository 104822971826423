/* global powerbi */
import { Grid, makeStyles, Paper, Typography } from '@material-ui/core';
import React, { useState, useEffect } from 'react';
import { withRouter, useParams } from 'react-router-dom/cjs/react-router-dom.min';
import api from '../../services/api';


const useStyles = makeStyles({
    customMarginTop: {
        marginTop: 16,
    },
    customPadding: {
        padding: 24
    },
    customMarginBottom: {
        marginBottom: 20
    },
});

const DepartmentReport = ({ match }) => {
    const companyId = localStorage.getItem("companyId");
    const { reportId,workspaceId } = useParams()
    const [loading, setLoading] = useState(false);
    const [embedData, setEmbedData] = useState();
    const [config, setConfig] = useState({
        clientId: '',
        authorityUrl: 'https://login.microsoftonline.com/organizations/',
        tenantId: '',
        pbiUsername: '',
        pbiPassword: '',
        clientSecret: '',
        authenticationMode: 'MasterUser',
        scopeBase: 'https://analysis.windows.net/powerbi/api/.default',
        workspaceId: ''
    });

    useEffect(() => {
        loadCredenciais();
    }, []); // Chama apenas uma vez ao montar o componente

    useEffect(() => {
        // Chama getTokenEmbeded apenas se clientId estiver presente
        if (config.clientId) {
            getTokenEmbeded();
        }
    }, [config.clientId]); // Dependência específica para clientId

    const loadCredenciais = async () => {
        try {
            setLoading(true);
            const { data } = await api.get(`/credenciais/${companyId}`);
            setConfig(prevConfig => ({
                ...prevConfig,
                ...data
            }));
        } catch (err) {
            console.error('Erro ao carregar as credenciais:', err);
        } finally {
            setLoading(false);
        }
    };

    const getTokenEmbeded = async () => {
        try {
            // Atualizar o workspaceId no objeto config
            config.workspaceId = workspaceId;
    
            const response = await api.post(`/getTokenEmbeded/${reportId}`, {
                body: config
            });
            setEmbedData(response.data);
            // console.log(response);
        } catch (error) {
            console.error('Erro ao obter o token:', error);
        }
    };
    
    

    const { reportid } = useParams();

    useEffect(() => {
      const loadReport = async () => {
        try {

          console.log(reportid)
  
          const reportContainer = document.getElementById('report-container');
  
          console.log(embedData)
          
          // Create a config object for embedding the report
          const reportLoadConfig = {
            type: 'report',
            tokenType: window['powerbi-client'].models.TokenType.Embed,
            accessToken: embedData.accessToken,
            embedUrl: embedData.embedUrl[0].embedUrl,
          };
  
          const report = powerbi.embed(reportContainer, reportLoadConfig);
  
          report.off('loaded');
          report.on('loaded', () => {
            console.log('Report load successful');
          });
  
          report.off('rendered');
          report.on('rendered', () => {
            console.log('Report render successful');
          });
  
          report.off('error');
          report.on('error', (event) => {
            console.error(event.detail);
          });
        } catch (error) {
          // Handle error
          console.error('Error loading report:', error);
        }
      };
  
      loadReport();
    }, [embedData]);
  

    return (
<div style={{ 
    height: 'calc(100% - 48px)', 
    display: 'flex', 
    flexDirection: 'column', 
    width: '100%', 
    alignItems: 'center', 
    justifyContent: 'center' 
}}>
    <div style={{ 
        width: '100%', // substituindo 'w-full-minus-68'
        height: '100vh', // substituindo 'h-screen-minus-56'
        position: 'relative', 
        top: '6px', 
        zIndex: 0 
    }} id="report-container"></div>
    <div className="error-container" style={{ display: 'none' }}></div>
</div>

    );
};

export default withRouter(DepartmentReport);
