import { Box, Grid, styled, TextField, Typography, useMediaQuery, useTheme } from '@material-ui/core';
import Button from '@mui/material/Button';
import Paper from '@mui/material/Paper';
import React, { useContext, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import api from '../../services/api';
import { AuthContext } from '../../context/Auth/AuthContext';
import { toast } from 'react-toastify';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';

export default function DepartmentSettings() {
  const [loading, setLoading] = useState(false);
  const [credenciais, setCredenciais] = useState(null);
  const companyId = localStorage.getItem("companyId");
  const { user } = useContext(AuthContext);

  const { register, handleSubmit, reset, watch, formState: { errors } } = useForm({
    defaultValues: {
      clientId: "",
      workspaceId: "5b000030-b000-400d-8e00-366363ed1588",
      pbiUsername: "",
      pbiPassword: "",
      clientSecret: "",
      tenantId: "",
      company_Id: companyId
    }
  });

  const history = useHistory();


  useEffect(() => {
    if (user && (!user.super && user.profile !== "admin")) {
      // console.log(user.ascview_usuario.ativo)
      if ((user.ascview_usuario && user.ascview_usuario.ativo !== 'Y')) {
        toast.error("Você não possui permissão para acessar essa página! Estamos lhe redirecionando.");
        setTimeout(() => {
          history.push(`/login`); // Redirecionar para a página de tickets ou outra página adequada
        }, 1000);
      }else if (!user.ascview_usuario){
        toast.error("Você não possui permissão para acessar essa página! Estamos lhe redirecionando.");
        setTimeout(() => {
          history.push(`/login`); // Redirecionar para a página de tickets ou outra página adequada
        }, 1000);
      }
    }
    
  }, [user, history]);

  


  // Função para carregar os dados ao iniciar o componente (GET)
  const loadCredenciais = async () => {
    try {
      setLoading(true);
      const { data } = await api.get(`/credenciais/${companyId}`);
      setCredenciais(data);
      reset(data); // Carregar os dados no formulário
      setLoading(false);
    } catch (err) {
      console.error('Erro ao carregar as credenciais:', err);
      setLoading(false);
    }
  };

  // Função para salvar os dados (POST ou PUT)
  const onSubmitForm = async (formData) => {
    try {
      if (credenciais) {
        await api.put(`/credenciais/${companyId}`, formData);
        alert('Dados atualizados com sucesso!');
      } else {
        await api.post(`/credenciais`, { ...formData, companyId });
        alert('Dados salvos com sucesso!');
      }
      reset();
      loadCredenciais(); // Recarregar os dados após a operação
    } catch (error) {
      console.error('Erro ao salvar os dados:', error);
    }
  };

  // Função para deletar as credenciais (DELETE)
  const handleDelete = async () => {
    try {
      await api.delete(`/credenciais/${companyId}`);
      alert('Credenciais deletadas com sucesso!');
      reset(); // Reseta o formulário após deletar
      setCredenciais(null); // Limpa o estado
    } catch (error) {
      console.error('Erro ao deletar as credenciais:', error);
    }
  };

  useEffect(() => {
    loadCredenciais(); // Carrega as credenciais ao montar o componente
  }, []);

  const globalTheme = useTheme();
  const Title = styled('h1')(({ theme }) => ({
    ...theme.typography.h1,
    backgroundColor: theme.palette.background.paper,
    padding: theme.spacing(4),
    margin: 0,
  }));

  const isMobile = useMediaQuery(globalTheme.breakpoints.down('sm')); // Detecta se é um dispositivo móvel

  return (
    <>
      <Title elevation={3} square sx={{ padding: 3 }}>
        <Typography variant="h5" component="h5" color='primary'>
          Configurações
        </Typography>
      </Title>
      <Paper
        xs={12} lg={6} id="paper-element"
        sx={{
          padding: 2,
          margin: isMobile ? '40px auto' : '40px 27px',
          width: '100%',
          maxWidth: 600,
          background: globalTheme.palette.background.paper
        }}>
        <Box component="form" onSubmit={handleSubmit(onSubmitForm)} sx={{ display: 'flex', flexDirection: 'column', gap: 2, width: '100%', margin: '0 auto' }}>
          <Typography variant="h6" component="h2" color='primary'>
            Editar Configurações do Embedded
          </Typography>

          <Grid container spacing={4} justifyContent="center" style={{ marginBottom: 40 }}>

                        {/* Campo Tenant ID */}
                        <Grid item xs={12}>
              <TextField
                label="Tenant ID"
                {...register('tenantId', { required: 'Tenant ID é obrigatório' })}
                error={!!errors.tenantId}
                helperText={errors.tenantId?.message}
                fullWidth
                InputLabelProps={{
                  shrink: !!watch('tenantId'), // Faz o label "shrink" se houver valor
                }}
              />
            </Grid>
            
            {/* Campo Client ID */}
            <Grid item xs={12}>
              <TextField
                label="Client ID"
                {...register('clientId', { required: 'Client ID é obrigatório' })}
                error={!!errors.clientId}
                helperText={errors.clientId?.message}
                fullWidth
                InputLabelProps={{
                  shrink: !!watch('clientId'), // Faz o label "shrink" se houver valor
                }}
              />
            </Grid>

            {/* Campo Workspace ID */}
            <Grid  style={{
                  display:'none'
                }} item xs={12}>
              <TextField
                style={{
                  display:'none'
                }}
                label="Workspace ID"
                {...register('workspaceId', { required: 'Workspace ID é obrigatório' })}
                error={!!errors.workspaceId}
                helperText={errors.workspaceId?.message}
                fullWidth
                InputLabelProps={{
                  shrink: !!watch('workspaceId'), // Faz o label "shrink" se houver valor
                }}
              />
            </Grid>

            {/* Campo PBI Username */}
            <Grid item xs={12}>
              <TextField
                label="PBI Username"
                {...register('pbiUsername', { required: 'PBI Username é obrigatório' })}
                error={!!errors.pbiUsername}
                helperText={errors.pbiUsername?.message}
                fullWidth
                InputLabelProps={{
                  shrink: !!watch('pbiUsername'), // Faz o label "shrink" se houver valor
                }}
              />
            </Grid>

            {/* Campo PBI Password */}
            <Grid item xs={12}>
              <TextField
                label="PBI Password"
                type="password"
                {...register('pbiPassword', { required: 'PBI Password é obrigatório' })}
                error={!!errors.pbiPassword}
                helperText={errors.pbiPassword?.message}
                fullWidth
                InputLabelProps={{
                  shrink: !!watch('pbiPassword'), // Faz o label "shrink" se houver valor
                }}
              />
            </Grid>

            {/* Campo Client Secret */}
            <Grid  style={{
                  display:'none'
                }} item xs={12}>
              <TextField
               style={{
                display:'none'
              }} 
                label="Client Secret"
                type="password"
                {...register('clientSecret')}
                error={!!errors.clientSecret}
                helperText={errors.clientSecret?.message}
                fullWidth
                InputLabelProps={{
                  shrink: !!watch('clientSecret'), // Faz o label "shrink" se houver valor
                }}
              />
            </Grid>


          </Grid>

          <Grid container item xs={12} display="flex" justifyContent="center">
            <Button
              item
              type="submit"
              variant="contained"
              sx={{
                color: "#ffffff",
                width: '50%',
                backgroundColor: "#3CA4AC",
                fontSize: {
                  xs: '0.75rem',
                  sm: '1rem',
                },
                '&:hover': {
                  backgroundColor: "#3CA4AC",
                },
              }}
            >
              <Typography variant='button' color='success'>
                Salvar Dados
              </Typography>
            </Button>

            <Button
              item
              onClick={handleDelete}
              variant="contained"
              color="error"
              sx={{ marginLeft: 2 }}
            >
              <Typography variant='button'>
                Limpar
              </Typography>
            </Button>
          </Grid>
        </Box>
      </Paper>
    </>
  );
}