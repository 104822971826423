import { Box, IconButton, Menu, MenuItem, Typography } from "@material-ui/core";
import { useState } from "react";
import { AiOutlineEdit } from "react-icons/ai";
import { FaEllipsisH } from "react-icons/fa";
import { MdDeleteOutline } from "react-icons/md";
import { RiArrowLeftRightFill } from "react-icons/ri";
import ModalRowTableDepartmentsDelete from "../ModalRowTableDepartmentsDelete";
import ModalRowTableDepartmentsEdit from "../ModalRowTableDepartmentsEdit";
import ModalRowTableDepartmentsPermission from "../ModalRowTableDepartmentsPermission";

export default function EditOrDeleteRow({ rowIdCell, hoveredRowId, setOpenEditAndDeleteButtons, fileType,object, departmentsDatabase, setDepartmentsDatabase, folderName, rowTextCell }) {
    const [anchorEl, setAnchorEl] = useState(null);
    const [openModalEdit, setOpenModalEdit] = useState(false);
    const [openModalDelete, setOpenModalDelete] = useState(false);
    const [openModalPermission, setOpenModalPermission] = useState(false);

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
        setOpenEditAndDeleteButtons(false); // Fecha o dropdown ao selecionar
    };

    return (
        <Box
            sx={{
                display: 'flex',
                gap: 3,
                alignItems: 'center',
                justifyContent: 'space-between',
                minWidth: '200px'
            }}
        >
            <Box
                display="flex"
                alignItems="center"
                justifyContent="space-between"
                mt={1}
                position='relative'
            >
                {/* Ícone de mais opções */}
                <Box
                    size="small"
                    sx={{
                        visibility: hoveredRowId === rowIdCell ? 'visible' : 'hidden',
                    }}
                >
                    <IconButton onClick={handleClick}>
                        <FaEllipsisH fontSize={20} />
                    </IconButton>
                </Box>

                {/* Dropdown com ícones de editar e deletar */}
                <Menu
                    anchorEl={anchorEl}
                    open={Boolean(anchorEl)}
                    onClose={handleClose}
                >
                    <MenuItem onClick={
                        () => {
                            setOpenModalEdit(true);
                            handleClose();
                        }}>
                        <AiOutlineEdit fontSize={20} />
                        <Typography variant="body2" sx={{ marginLeft: 1 }}>Editar</Typography>
                    </MenuItem>

                    <MenuItem onClick={
                        () => {
                            setOpenModalDelete(true);
                            handleClose();
                        }}>
                        <MdDeleteOutline fontSize={20} />
                        <Typography variant="body2" sx={{ marginLeft: 1 }}>Deletar</Typography>
                    </MenuItem>

                    <MenuItem onClick={
                        () => {
                            setOpenModalPermission(true);
                            handleClose();
                        }}>
                        <RiArrowLeftRightFill fontSize={20} />
                        <Typography variant="body2" sx={{ marginLeft: 1 }}>Permitir</Typography>
                    </MenuItem>
                </Menu>
            </Box>

            <ModalRowTableDepartmentsEdit
                openModalEdit={openModalEdit}
                setOpenModalEdit={setOpenModalEdit}
                fileType={fileType}
                object={object}
                folderName={folderName}
                rowTextCell={rowTextCell}
            />

            <ModalRowTableDepartmentsDelete
                openModalDelete={openModalDelete}
                setOpenModalDelete={setOpenModalDelete}
                fileType={fileType}
                departmentsDatabase={departmentsDatabase}
                setDepartmentsDatabase={setDepartmentsDatabase}
                rowIdCell={rowIdCell}
                folderName={folderName}
                rowTextCell={rowTextCell}
            />

            <ModalRowTableDepartmentsPermission
                openModalPermission={openModalPermission}
                setOpenModalPermission={setOpenModalPermission}
                rowIdCell={rowIdCell}
                fileType={fileType}
                object={object}
            />
        </Box>
    )
}