import { useTheme } from '@material-ui/core';
import { Breadcrumbs, Link, Typography } from '@mui/material';
import React, { useContext, useEffect, useState } from 'react';
import { Link as RouterLink, useLocation } from 'react-router-dom';
import api from '../../services/api';
import { AuthContext } from '../../context/Auth/AuthContext';
import { useParams, useHistory } from 'react-router-dom';

const CustomBreadcrumbs = () => {
    const location = useLocation();
    const pathnames = location.pathname.split('/').filter((x) => x);
    //console.log(pathnames);

    const filteredPathnames = pathnames.filter((value) => value !== "departments");

    const theme = useTheme();
    const { departmentId } = useParams();
    const [loading, setLoading] = useState(true);
    const parentIdTrat = departmentId ? Number(departmentId) : 0;
    const { user, handleLogout } = useContext(AuthContext);
    const [breadcrumb, setBreadcrumb] = useState();

    useEffect(() => {
        const fetchFolders = async () => {
            try {
                setLoading(true);

                const response = await api.get("/pastas", {
                    params: { // Aqui está a correção
                        parentId: parentIdTrat,
                        companyId: user.companyId,
                        usuario_id: user.email

                    }
                });
                console.log(response)
                setBreadcrumb(response.data.breadcrumb)
                // console.log(response.data)
                // console.log(breadcrumb)
                // // setFolders(response.data.pastas);
                // setReports(response.data.relatorios || []); // Armazena os relatórios
                // const filteredFolders = response.data.pastas.filter(folder => folder.tipo !== 'pai');

                // setFolders(filteredFolders);
                // setDepartmentsDataOrdered(filteredFolders); // Inicializa a lista ordenada
                // // setDepartmentsDataOrdered(response.data.pastas); // Inicializa a lista ordenada
                // setLoading(false);
                // const parentFolder = response.data.pastas.find(folder => folder.tipo === 'pai');
                // // console.log(parentFolder)
                // setParentDepartmentName(parentFolder ? parentFolder.nome : "Departamentos");

            } catch (error) {
                console.error('Erro ao carregar pastas:', error);
                setLoading(false);
            }
        };

        fetchFolders();
    }, []);

    // console.log("nome dos departamentos", departmentsDataOrdered);
    // console.log("filteredPathnames", filteredPathnames);
    // console.log("pathnames", pathnames);
    // console.log("folders", folders)
    // console.log("reports", reports)
    return (
        // <></>

        <Breadcrumbs
            aria-label="breadcrumb"
            separator="/"  // Configura o separador como ">"
            sx={{ fontSize: 12, color: "gray" }}
        >
            {/* Link para a página inicial */}
            <Link
                component={RouterLink}
                to="/departments"
                underline={filteredPathnames.length === 0 ? 'none' : 'hover'}
                color="#666666"
                sx={{ fontSize: 12 }}
            >
                <Typography color={theme.palette.text.primary} sx={{ fontSize: 12 }} >
                    DEPARTAMENTOS
                </Typography>
            </Link>

            {breadcrumb && breadcrumb.length > 0 && breadcrumb.map((value, index) => {
                const to = `${filteredPathnames.slice(0, index + 1).join('/')}`;
                const isLast = index === breadcrumb.length - 1;

                return isLast ? (
                    <Typography 
                        sx={{ fontSize: '12px', lineHeight: '1.2' }}
                        color={theme.palette.text.primary}
                        key={value.id}>
                        {value.nome.toUpperCase()}
                    </Typography>
                ) : (
                    <Link
                        component={RouterLink}
                        underline="hover"
                        color="#666666"
                        to={value.id.toString()}
                        key={value.id}
                        sx={{ fontSize: 12 }}>
                        {value.nome.toUpperCase()}
                    </Link>
                );
            })}

        </Breadcrumbs>
    );
};

export default CustomBreadcrumbs;
