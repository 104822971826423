import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import Typography from '@mui/material/Typography';
import React, { useState, useContext, useEffect } from 'react';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import { useForm } from 'react-hook-form';
import { AuthContext } from '../../context/Auth/AuthContext';
import usePasta from '../../hooks/usePasta/usePasta';
import api from '../../services/api';
import { useParams } from 'react-router-dom/cjs/react-router-dom.min';
import useRelatorio from '../../hooks/useRelatorio/useRelatorio';
import axios from 'axios';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: { xs: '90%', sm: 800 },
  bgcolor: 'background.paper',
  border: 'none',
  boxShadow: 24,
  p: 4,
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  gap: 2,
  borderRadius: '10px',
};

export default function ModalDepartmentNewItem({ openModal, setOpenModal, typeOfModal, parentId, token }) {
  const handleClose = () => setOpenModal(false);
  const { register, handleSubmit, reset, watch, formState: { errors } } = useForm();
  const { departmentId } = useParams();
  const parentIdTrat = departmentId ? Number(departmentId) : 0;
  const { user } = useContext(AuthContext);
  const { createPasta } = usePasta();
  const { createRelatorio } = useRelatorio();

  const [workspaces, setWorkspaces] = useState([]);
  const [reports, setReports] = useState([]);
  const [selectedWorkspace, setSelectedWorkspace] = useState('');
  const [selectedReport, setSelectedReport] = useState('');

  useEffect(() => {
    // Fetch the list of workspaces when the modal is opened
    if (openModal) {
      fetchWorkspaces();
    }
  }, [openModal]);

  const fetchWorkspaces = async () => {
    try {
      const response = await axios.get('https://api.powerbi.com/v1.0/myorg/groups/', {
        headers: {
          'Authorization': `${token && token[0].Authorization}`,
        }
      });
      setWorkspaces(response.data.value);
    } catch (error) {
      console.error('Erro ao buscar workspaces:', error);
    }
  };

  const fetchReports = async (workspaceId) => {
    try {
      const response = await axios.get(`https://api.powerbi.com/v1.0/myorg/groups/${workspaceId}/reports`, {
        headers: {
          'Authorization': `${token && token[0].Authorization}`,
        }
      });
      setReports(response.data.value);
    } catch (error) {
      console.error('Erro ao buscar relatórios:', error);
    }
  };

  const onWorkspaceChange = (event) => {
    const workspaceId = event.target.value;
    setSelectedWorkspace(workspaceId);
    fetchReports(workspaceId);
  };

  const onSubmitForm = async (data) => {
    try {
      let payload;

      if (typeOfModal === "Item") {
        payload = {
          reportId: selectedReport,
          nome: data.reportName,
          workspaceId: selectedWorkspace,
          pasta_id: parentIdTrat,
        };

        console.log(payload)

        await createRelatorio(payload, parentIdTrat);
      } else {
        payload = {
          parentId: parentId,
          companyId: user.companyId,
          nome: data.reportName,
        };

        await createPasta(payload, parentIdTrat, user);
      }

      reset();
      handleClose();
    } catch (error) {
      console.error('Erro ao salvar os dados:', error);
    }
  };

  return (
    <Modal
      open={openModal}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box component="form" onSubmit={handleSubmit(onSubmitForm)} sx={style}>
        <Typography id="modal-modal-title" variant="h6" component="h2" sx={{ textAlign: 'center' }}>
          {typeOfModal === "Item" ? "Adicionar Novo Relatório" : "Adicionar Nova Pasta"}
        </Typography>

        <Grid container spacing={2} sx={{ width: '100%' }}>
          {typeOfModal === "Item" && (
            <>
              <Grid item xs={12}>
                <Select
                  value={selectedWorkspace}
                  onChange={onWorkspaceChange}
                  fullWidth
                  displayEmpty
                >
                  <MenuItem value="" disabled>
                    Selecione o Workspace
                  </MenuItem>
                  {workspaces.map((workspace) => (
                    <MenuItem key={workspace.id} value={workspace.id}>
                      {workspace.name}
                    </MenuItem>
                  ))}
                </Select>
              </Grid>

              <Grid item xs={12}>
                <Select
                  value={selectedReport}
                  onChange={(e) => setSelectedReport(e.target.value)}
                  fullWidth
                  displayEmpty
                  disabled={!selectedWorkspace}
                >
                  <MenuItem value="" disabled>
                    Selecione o Relatório
                  </MenuItem>
                  {reports.map((report) => (
                    <MenuItem key={report.id} value={report.id}>
                      {report.name}
                    </MenuItem>
                  ))}
                </Select>
              </Grid>

              <Grid item xs={12}>
                <TextField
                  label="Nome do Relatório"
                  variant="outlined"
                  fullWidth
                  {...register('reportName', { required: 'Nome do relatório obrigatório' })}
                  error={!!errors.reportName}
                  helperText={errors.reportName?.message}
                />
              </Grid>
            </>
          )}

          {typeOfModal === "Folder" && (
            <Grid item xs={12}>
              <TextField
                label="Nome da Pasta"
                variant="outlined"
                fullWidth
                {...register('reportName', { required: 'Nome da pasta é obrigatório' })}
                error={!!errors.reportName}
                helperText={errors.reportName?.message}
              />
            </Grid>
          )}
        </Grid>

        <Button
          variant="contained"
          sx={{ color: "#fff", width: '50%', backgroundColor: "#3CA4AC" }}
          type="submit"
        >
          Salvar
        </Button>
      </Box>
    </Modal>
  );
}
