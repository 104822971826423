  import React, { useState, useEffect, useReducer, useContext } from "react";
  import { toast } from "react-toastify";
  import { useHistory } from "react-router-dom";

  import { makeStyles } from "@material-ui/core/styles";
  import Paper from "@material-ui/core/Paper";
  import Button from "@material-ui/core/Button";
  import Table from "@material-ui/core/Table";
  import TableBody from "@material-ui/core/TableBody";
  import TableCell from "@material-ui/core/TableCell";
  import TableHead from "@material-ui/core/TableHead";
  import TableRow from "@material-ui/core/TableRow";
  import IconButton from "@material-ui/core/IconButton";
  import SearchIcon from "@material-ui/icons/Search";
  import TextField from "@material-ui/core/TextField";
  import InputAdornment from "@material-ui/core/InputAdornment";

  import DeleteOutlineIcon from "@material-ui/icons/DeleteOutline";
  import EditIcon from "@material-ui/icons/Edit";

  import MainContainer from "../../components/MainContainer";
  import MainHeader from "../../components/MainHeader";
  import MainHeaderButtonsWrapper from "../../components/MainHeaderButtonsWrapper";
  import Title from "../../components/Title";

  import api from "../../services/api";
  import { i18n } from "../../translate/i18n";
  import TableRowSkeleton from "../../components/TableRowSkeleton";
  import UserAscViewModal from "../../components/UserAscViewModal";
  import ConfirmationModal from "../../components/ConfirmationModal";
  import toastError from "../../errors/toastError";
  import { socketConnection } from "../../services/socket";
  import { AuthContext } from "../../context/Auth/AuthContext";

  const reducer = (state, action) => {
    if (action.type === "LOAD_USERS") {
      const users = action.payload;
      const newUsers = [];
      // console.log(users)

      users.forEach((user) => {
        const userIndex = state.findIndex((u) => u.id === user.id);
        if (userIndex !== -1) {
          state[userIndex] = user;
        } else {
          newUsers.push(user);
        }
      });

      return [...state, ...newUsers];
    }

    if (action.type === "UPDATE_USUARIOS") {
      const user = action.payload;
      const userIndex = state.findIndex((u) => u.id === user.id);

      if (userIndex !== -1) {
        state[userIndex] = user;
        return [...state];
      } else {
        return [user, ...state];
      }
    }

    if (action.type === "DELETE_USER") {
      const userId = action.payload;

      const userIndex = state.findIndex((u) => u.id === userId);
      if (userIndex !== -1) {
        state.splice(userIndex, 1);
      }
      return [...state];
    }

    if (action.type === "RESET") {
      return [];
    }
  };

  const useStyles = makeStyles((theme) => ({
    mainPaper: {
      flex: 1,
      padding: theme.spacing(1),
      overflowY: "scroll",
      ...theme.scrollbarStyles,
    },  inactive: {
      backgroundColor: theme.palette.grey[300], // Use um tom de cinza moderno
    },
  }));

  const DepartamentsUsers = () => {
    const classes = useStyles();
    const history = useHistory();
    const { user } = useContext(AuthContext);

    const [loading, setLoading] = useState(false);
    const [pageNumber, setPageNumber] = useState(1);
    const [hasMore, setHasMore] = useState(false);
    const [selectedUser, setSelectedUser] = useState(null);
    const [deletingUser, setDeletingUser] = useState(null);
    const [UserAscViewModalOpen, setUserAscViewModalOpen] = useState(false);
    const [confirmModalOpen, setConfirmModalOpen] = useState(false);
    const [searchParam, setSearchParam] = useState("");
    const [users, dispatch] = useReducer(reducer, []);


    useEffect(() => {
      if (user && (!user.super && user.profile !== "admin")) {
        // console.log(user.ascview_usuario.ativo)
        if ((user.ascview_usuario && user.ascview_usuario.ativo !== 'Y')) {
          toast.error("Você não possui permissão para acessar essa página! Estamos lhe redirecionando.");
          setTimeout(() => {
            history.push(`/login`); // Redirecionar para a página de tickets ou outra página adequada
          }, 1000);
        }else if (!user.ascview_usuario){
          toast.error("Você não possui permissão para acessar essa página! Estamos lhe redirecionando.");
          setTimeout(() => {
            history.push(`/login`); // Redirecionar para a página de tickets ou outra página adequada
          }, 1000);
        }
      }
      
    }, [user, history]);
  
    
    
    useEffect(() => {
      dispatch({ type: "RESET" });
      setPageNumber(1);
    }, [searchParam]);

    useEffect(() => {
      setLoading(true);
      const delayDebounceFn = setTimeout(() => {
        const fetchUsers = async () => {

          try {
            const { data } = await api.get(`/usuarios/company/${user.companyId}`);        
            // console.log(data)  
            dispatch({ type: "LOAD_USERS", payload: data });
            setHasMore(data.hasMore);
            setLoading(false);
          } catch (err) {
            toastError(err);
          }
        };
        fetchUsers();
      }, 0);
      return () => clearTimeout(delayDebounceFn);
    }, [searchParam]);
    // const companyId = localStorage.getItem("companyId");

    useEffect(() => {
      const companyId = localStorage.getItem("companyId");
      const socket = socketConnection({ companyId });
      // console.log('Passei aqui')
      socket.on(`company-${companyId}-usuarios`, (data) => {
        // console.log(data.action)

        if (data.action === "UPDATE_USUARIOS") {
          // console.log('data_user',data)
          dispatch({ type: "UPDATE_USUARIOS", payload: data.usuarios });
        }

        // if (data.action === "DELETE_USUARIO") {
        //   console.log(data)
        //   dispatch({ type: "DELETE_USER", payload: data });
        // }
      });

      return () => {
        socket.disconnect();
      };
    }, []);
    // console.log('Passei aqui')
    // console.log(companyId)

    const handleOpenUserAscViewModal = () => {
      setSelectedUser(null);
      setUserAscViewModalOpen(true);
    };

    const handleCloseUserAscViewModal = () => {
      setSelectedUser(null);
      setUserAscViewModalOpen(false);
    };

    const handleSearch = (event) => {
      setSearchParam(event.target.value.toLowerCase());
    };

    const handleEditUser = (user) => {
      setSelectedUser(user);
      setUserAscViewModalOpen(true);
    };

    const handleDeleteUser = async (userId) => {
      try {
        await api.delete(`/usuarios/${userId}`,{
          companyId: user.companyId
        });
        dispatch({ type: "DELETE_USER", payload: userId });

        toast.success(i18n.t("users.toasts.deleted"));
      } catch (err) {
        if (err.response && err.response.data && err.response.data.message) {
          toast.error(err.response.data.message);
        } else {
          toastError(err);
        }
      }
      setDeletingUser(null);
      setSearchParam("");
      setPageNumber(1);
    };

    const loadMore = () => {
      setPageNumber((prevState) => prevState + 1);
    };

    const handleScroll = (e) => {
      if (!hasMore || loading) return;
      const { scrollTop, scrollHeight, clientHeight } = e.currentTarget;
      if (scrollHeight - (scrollTop + 100) < clientHeight) {
        loadMore();
      }
    };
    // console.log(user)

    return (
      <MainContainer>
        <ConfirmationModal
          title={
            deletingUser &&
            `${i18n.t("users.confirmationModal.deleteTitle")} ${
              deletingUser.name
            }?`
          }
          open={confirmModalOpen}
          onClose={setConfirmModalOpen}
          onConfirm={() => handleDeleteUser(deletingUser.id)}
        >
          {i18n.t("users.confirmationModal.deleteMessage")}
        </ConfirmationModal>
        <UserAscViewModal
          open={UserAscViewModalOpen}
          onClose={handleCloseUserAscViewModal}
          aria-labelledby="form-dialog-title"
          userId={selectedUser && selectedUser.email}
        />
        <MainHeader>
          <Title>{i18n.t("users.title")}</Title>
          <MainHeaderButtonsWrapper>
            <TextField
              placeholder={i18n.t("contacts.searchPlaceholder")}
              type="search"
              value={searchParam}
              onChange={handleSearch}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <SearchIcon style={{ color: "gray" }} />
                  </InputAdornment>
                ),
              }}
            />
            <Button
              variant="contained"
              color="primary"
              onClick={handleOpenUserAscViewModal}
            >
              {i18n.t("users.buttons.add")}
            </Button>
          </MainHeaderButtonsWrapper>
        </MainHeader>
        <Paper
          className={classes.mainPaper}
          variant="outlined"
          onScroll={handleScroll}
        >
          <Table size="small">
            <TableHead>
              <TableRow>
                <TableCell align="center">{i18n.t("users.table.name")}</TableCell>
                <TableCell align="center">
                  {i18n.t("users.table.email")}
                </TableCell>
                <TableCell align="center">
                  {i18n.t("users.table.profile")}
                </TableCell>
                <TableCell align="center">
                    Ativo
                </TableCell>
                <TableCell align="center">
                  {i18n.t("users.table.actions")}
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              <>
                {users.map((user) => (
                  <TableRow key={user.id} className={user.ativo == 'Y' ? '' : classes.inactive}>
                    <TableCell align="center">{user.nome}</TableCell>
                    <TableCell align="center">{user.email}</TableCell>
                    <TableCell align="center">{user.profile}</TableCell>
                    <TableCell align="center">{user.ativo == 'Y' ? "Ativo" : "Inativo"}</TableCell>
                    <TableCell align="center">
                      <IconButton
                        size="small"
                        onClick={() => handleEditUser(user)}
                      >
                        <EditIcon />
                      </IconButton>

                      <IconButton
                        size="small"
                        onClick={(e) => {
                          setConfirmModalOpen(true);
                          setDeletingUser(user);
                        }}
                      >
                        <DeleteOutlineIcon />
                      </IconButton>
                    </TableCell>
                  </TableRow>
                ))}
                {loading && <TableRowSkeleton columns={5} />}
              </>
            </TableBody>
          </Table>
        </Paper>
      </MainContainer>
    );
  };

  export default DepartamentsUsers;
