import React, { useState, useEffect, useContext } from "react";
import * as Yup from "yup";
import { Formik, Form, Field } from "formik";
import { toast } from "react-toastify";

import { makeStyles } from "@material-ui/core/styles";
import { green } from "@material-ui/core/colors";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import CircularProgress from "@material-ui/core/CircularProgress";
import { InputLabel, MenuItem, Select } from "@material-ui/core";

import { i18n } from "../../translate/i18n";
import api from "../../services/api";
import toastError from "../../errors/toastError";
import { AuthContext } from "../../context/Auth/AuthContext";

const useStyles = makeStyles(theme => ({
    root: {
        display: "flex",
        flexWrap: "wrap",
    },
    multFieldLine: {
        display: "flex",
        "& > *:not(:last-child)": {
            marginRight: theme.spacing(1),
        },
    },
    btnWrapper: {
        position: "relative",
    },
    buttonProgress: {
        color: green[500],
        position: "absolute",
        top: "50%",
        left: "50%",
        marginTop: -12,
        marginLeft: -12,
    },
}));

const UserSchema = Yup.object().shape({
    nome: Yup.string()
        .min(2, "Muito curto!")
        .max(50, "Muito longo!")
        .required("Obrigatório"),
    email: Yup.string().email("Email inválido").required("Obrigatório"),
    profile: Yup.string().required("Obrigatório"),
    ativo: Yup.string().oneOf(['Y', 'N']).required("Obrigatório"),
});

const UserAscViewModal = ({ open, onClose, userId }) => {
    const classes = useStyles();
    const { user } = useContext(AuthContext);
    const [userAscView, setUserAscView] = useState({
        nome: "",
        email: "",
        profile: "",
        ativo: "Y"
    });

    useEffect(() => {
        const fetchUser = async () => {
            if (!userId) return;
            try {
                const { data } = await api.get(`/usuarios/${userId}`);
                setUserAscView(prevState => ({ ...prevState, ...data }));
            } catch (err) {
                toastError(err);
            }
        };
        fetchUser();
    }, [userId, open]);

    const handleClose = () => {
        onClose();
        setUserAscView({
            nome: "",
            email: "",
            profile: "",
            ativo: "Y"
        });
    };

    const handleSaveUser = async values => {
        const userData = { ...values, companyId: user.companyId };
        try {
            if (userId) {
                await api.put(`/usuarios/${userId}`, userData);
            } else {
                await api.post("/usuarios", userData);
            }
            toast.success(i18n.t("userModal.success"));
        } catch (err) {
            toastError(err);
        }
        handleClose();
    };

    return (
        <div className={classes.root}>
            <Dialog
                open={open}
                onClose={handleClose}
                maxWidth="xs"
                fullWidth
                scroll="paper"
            >
                <DialogTitle>
                    {userId ? i18n.t("userModal.title.edit") : i18n.t("userModal.title.add")}
                </DialogTitle>
                <Formik
                    initialValues={userAscView}
                    enableReinitialize={true}
                    validationSchema={UserSchema}
                    onSubmit={(values, actions) => {
                        setTimeout(() => {
                            handleSaveUser(values);
                            actions.setSubmitting(false);
                        }, 400);
                    }}
                >
                    {({ touched, errors, isSubmitting }) => (
                        <Form>
                            <DialogContent dividers>
                                <div className={classes.multFieldLine}>
                                    <Field
                                        as={TextField}
                                        label={i18n.t("userModal.form.name")}
                                        autoFocus
                                        name="nome"
                                        error={touched.nome && Boolean(errors.nome)}
                                        helperText={touched.nome && errors.nome}
                                        variant="outlined"
                                        margin="dense"
                                        fullWidth
                                    />
                                    <Field
                                        as={TextField}
                                        label={i18n.t("userModal.form.email")}
                                        name="email"
                                        error={touched.email && Boolean(errors.email)}
                                        helperText={touched.email && errors.email}
                                        variant="outlined"
                                        margin="dense"
                                        fullWidth
                                    />
                                </div>
                                <InputLabel style={{fontSize:12, marginBottom:5, marginTop:5}}>{i18n.t("userModal.form.profile")}</InputLabel>
                                <Field
                                    
                                    as={Select}
                                    name="profile"
                                    variant="outlined"
                                    fullWidth
                                    margin="dense"
                                    error={touched.profile && Boolean(errors.profile)}
                                >
                                    <MenuItem value="admin">Admin</MenuItem>
                                    <MenuItem value="user">User</MenuItem>
                                </Field>

                                <InputLabel style={{fontSize:12, marginTop:5, marginBottom:5}}>Ativo</InputLabel>
                                <Field
                                    as={Select}
                                    name="ativo"
                                    variant="outlined"
                                    fullWidth
                                    margin="dense"
                                    error={touched.ativo && Boolean(errors.ativo)}
                                >
                                    <MenuItem value="Y">Ativo</MenuItem>
                                    <MenuItem value="N">Inativo</MenuItem>
                                </Field>
                            </DialogContent>
                            <DialogActions>
                                <Button onClick={handleClose} color="secondary" disabled={isSubmitting}>
                                    {i18n.t("userModal.buttons.cancel")}
                                </Button>
                                <Button type="submit" color="primary" disabled={isSubmitting} className={classes.btnWrapper}>
                                    {userId ? i18n.t("userModal.buttons.okEdit") : i18n.t("userModal.buttons.okAdd")}
                                    {isSubmitting && (
                                        <CircularProgress size={24} className={classes.buttonProgress} />
                                    )}
                                </Button>
                            </DialogActions>
                        </Form>
                    )}
                </Formik>
            </Dialog>
        </div>
    );
};

export default UserAscViewModal;
