import React, { useEffect, useState, useRef } from 'react';
import Chart from 'react-apexcharts';
import { startOfHour, parseISO, format } from "date-fns";
import useTickets from '../../hooks/useTickets';
import { Typography } from '@material-ui/core';
import { useTheme } from "@material-ui/core/styles";

const NewChart2 = () => {

    const theme = useTheme();
    // Usando useRef para manter a data atual
    const date = useRef(new Date().toISOString());

    // Chamando o hook useTickets para obter os dados do backend
    const { tickets } = useTickets({ date: date.current });

    // Estado para armazenar os dados formatados para o gráfico
    const [chartData, setChartData] = useState([
        { time: "01:00", amount: 0 },
        { time: "02:00", amount: 0 },
        { time: "03:00", amount: 0 },
        { time: "04:00", amount: 0 },
        { time: "05:00", amount: 0 },
        { time: "06:00", amount: 0 },
        { time: "07:00", amount: 0 },
        { time: "08:00", amount: 0 },
        { time: "09:00", amount: 0 },
        { time: "10:00", amount: 0 },
        { time: "11:00", amount: 0 },
        { time: "12:00", amount: 0 },
        { time: "13:00", amount: 0 },
        { time: "14:00", amount: 0 },
        { time: "15:00", amount: 0 },
        { time: "16:00", amount: 0 },
        { time: "17:00", amount: 0 },
        { time: "18:00", amount: 0 },
        { time: "19:00", amount: 0 },
        { time: "20:00", amount: 0 },
        { time: "21:00", amount: 0 },
        { time: "22:00", amount: 0 },
        { time: "23:00", amount: 0 },
        { time: "00:00", amount: 0 },
    ]);

    // useEffect para atualizar o chartData com base nos tickets
    useEffect(() => {
        setChartData(prevState => {
            let aux = [...prevState];

            aux.forEach(a => {
                tickets.forEach(ticket => {
                    // Verifica se a hora do ticket corresponde à hora no gráfico
                    if (format(startOfHour(parseISO(ticket.createdAt)), "HH:mm") === a.time) {
                        a.amount++;
                    }
                });
            });

            return aux;
        });
    }, [tickets]);

    const maxAmount = Math.max(...chartData.map(data => data.amount));

    // Configurações do gráfico ApexCharts
    const options = {
        series: [{
            name: "Atendimentos Diários",
            data: chartData.map(data => data.amount), // Extrai apenas a quantidade
        }],
        chart: {
            type: 'bar',
            height: 300
        },
        xaxis: {
            categories: chartData.map(data => data.time), // Extrai as horas para o eixo X
            labels: {
                style: {
                    colors: '#808080',  // Cor cinza para os números do eixo x
                    fontSize: '12px'    // Tamanho da fonte dos números
                }
            },
            tickAmount: 24,
        },
        grid: {
            show: true,  // Garante que a grade seja exibida
            borderColor: '#e0e0e0', // Cor da borda da grade
            strokeDashArray: 3, // Estilo do traço da grade
            position: 'back',  // Coloca a grade atrás das barras
            xaxis: {
                lines: {
                    show: true, // Exibe as linhas verticais
                }
            },
            yaxis: {
                lines: {
                    show: true, // Exibe as linhas horizontais
                }
            }
        },
        tooltip: {
            y: {
                formatter: (val) => `${val} atendimentos`
            }
        },
        yaxis: {
            title: {
                text: "Tickets",
                style: {
                    fontSize: '18px',
                    fontWeight: 200,
                    color: theme.palette.text.primary
                }
            },
            labels: {
                style: {
                    colors: theme.palette.text.secondary,  // Cor cinza para os números do eixo Y
                    fontSize: '12px'    // Tamanho da fonte dos números
                }
            },
            tickAmount: maxAmount < 5 ? 5 : maxAmount,
            forceNiceScale: true,
            decimalsInFloat: 0,
            min: 0,
            max: maxAmount < 5 ? 5 : maxAmount,
        },
        responsive: [{
            breakpoint: 768, // Defina o ponto de quebra
            options: {
                xaxis: {
                    tickAmount: 5, 
                },
                grid:{
                    xaxis: {
                        lines: {
                            show: false, 
                        }
                    },
                }
            },
        }],
    };

    return (
        <div id="chart">
            <Typography component="h2" variant="h6" color="primary" gutterBottom>
                {`Atendimentos hoje: ${tickets.length}`}
            </Typography>
            <Chart options={options} series={options.series} type="bar" height={300} />
        </div>
    );
};

export default NewChart2; 