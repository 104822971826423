import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import Typography from '@mui/material/Typography';
import React from 'react';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import { useForm } from 'react-hook-form';
import usePasta from '../../hooks/usePasta/usePasta';  // Hook para manipular pastas
import useRelatorio from '../../hooks/useRelatorio/useRelatorio';  // Hook para manipular relatórios
import { useParams } from 'react-router-dom/cjs/react-router-dom';

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: { xs: '90%', sm: 800 },
    bgcolor: 'background.paper',
    border: 'none',
    boxShadow: 24,
    p: 4,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    gap: 2,
    borderRadius: '10px',
};

export default function ModalRowTableDepartmentsEdit({ openModalEdit, setOpenModalEdit, fileType, object, folderName, rowTextCell }) {

    const { departmentId } = useParams();

    const { register, handleSubmit, reset, watch, formState: { errors } } = useForm({
        defaultValues: {
            reportId: object?.reportId || "",
            reportName: object?.nome || "",
            workSpaceID: object?.workspaceId || "",
        },
    });

    const { updatePasta } = usePasta();
    const { updateRelatorio } = useRelatorio();

    const handleClose = () => setOpenModalEdit(false);


    const onSubmitForm = async (data) => {

        console.log(departmentId)

        const parentIdTrat = departmentId ? Number(departmentId) : 0;
        console.log(parentIdTrat)
        
        try {
            if (fileType === 'relatorio') {
                await updateRelatorio(object.id, { id: object.id, nome: data.reportName, workspaceId: data.workSpaceID }, parentIdTrat);  // Chama a função de atualização do relatório
            } else {
                await updatePasta({ id: object.id, nome: data.reportName }, parentIdTrat);  // Chama a função de atualização da pasta
            }

            reset();
            handleClose();
        } catch (error) {
            console.error('Erro ao salvar os dados:', error);
        }
    };

    return (
        <div>
            <Modal
                open={openModalEdit}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box component="form" onSubmit={handleSubmit(onSubmitForm)} sx={style}>
                    <Typography id="modal-modal-title" variant="h6" component="h2" sx={{ textAlign: 'center' }}>
                        {fileType === 'relatorio' ? `Editar relatório: ${folderName}` : `Editar pasta: ${folderName}`}
                    </Typography>

                    <Grid container spacing={2} sx={{ width: '100%' }}>
                        {fileType === "relatorio" && (
                            <>
                                <Grid item xs={12}>
                                    <TextField
                                        id="outlined-basic-1"
                                        label="Report ID"
                                        variant="outlined"
                                        fullWidth
                                        {...register('reportId', { required: 'ID do relatório obrigatório' })}
                                        error={!!errors.reportId}
                                        helperText={errors.reportId?.message}
                                    />
                                </Grid>

                                <Grid item xs={12}>
                                    <TextField
                                        id="outlined-basic-2"
                                        label="Report Name"
                                        variant="outlined"
                                        fullWidth
                                        {...register('reportName', { required: 'Nome do relatório obrigatório' })}
                                        error={!!errors.reportName}
                                        helperText={errors.reportName?.message}
                                    />
                                </Grid>

                                <Grid item xs={12}>
                                    <TextField
                                        id="outlined-basic-3"
                                        label="Workspace ID"
                                        variant="outlined"
                                        fullWidth
                                        {...register('workSpaceID', { required: 'ID do Workspace é obrigatório' })}
                                        error={!!errors.workSpaceID}
                                        helperText={errors.workSpaceID?.message}
                                    />
                                </Grid>
                            </>
                        )}

                        {fileType !== "relatorio" && (
                            <Grid item xs={12}>
                                <TextField
                                    id="outlined-basic-name"
                                    label="Nome da Pasta"
                                    variant="outlined"
                                    fullWidth
                                    {...register('reportName', { required: 'Nome da pasta é obrigatório' })}
                                    error={!!errors.reportName}
                                    helperText={errors.reportName?.message}
                                />
                            </Grid>
                        )}
                    </Grid>

                    <Button
                        variant="contained"
                        color="primary"
                        sx={{
                            color: "#fff",
                            width: '50%',
                            backgroundColor: "#3CA4AC",
                            fontSize: { xs: '0.75rem', sm: '1rem' },
                            '&:hover': { backgroundColor: "#3CA4AC" },
                        }}
                        type="submit"
                    >
                        Salvar
                    </Button>

                    <Button
                        onClick={handleClose}
                        variant="contained"
                        color="inerit"
                        sx={{
                            width: '50%',
                            fontSize: { xs: '0.75rem', sm: '1rem' }
                        }}
                    >
                        Cancelar
                    </Button>
                </Box>
            </Modal>
        </div>
    );
}
