import { useState, useEffect, useContext } from 'react';
import api from '../../services/api';
import { AuthContext } from '../../context/Auth/AuthContext';
import { socketConnection } from '../../services/socket';
import usePasta from '../usePasta/usePasta';
import { toast } from 'react-toastify';

const useRelatorio = () => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [relatorio, setRelatorio] = useState(null);
  const { user } = useContext(AuthContext);
  const { fetchPastas } = usePasta();
  const companyId = localStorage.getItem("companyId");

  // Função para adicionar atraso de tempo
  const sleep = (ms) => new Promise(resolve => setTimeout(resolve, ms));

  // Função para criar um novo relatório
  const createRelatorio = async (data, parentIdTrat) => {
    setLoading(true);
    setError(null);

    const payload = {
      reportId: data.reportId,
      nome: data.nome,
      workspaceId: data.workspaceId,
      pasta_id: parentIdTrat, // ou algum outro ID se necessário
    };

    try {
      const response = await api.post('/relatorios', payload);
      setRelatorio(response.data);
      toast.success('Relatório criado com sucesso');
      // console.log('Relatório criado com sucesso:', response.data);

      await sleep(1500); // Espera de 1.5 segundos
      await fetchPastas(parentIdTrat);

      return response.data;
    } catch (err) {
      setError(err);
      console.error('Erro ao criar relatório:', err);
    } finally {
      setLoading(false);
    }
  };

  // Função para atualizar um relatório existente
  const updateRelatorio = async (id, data, parentIdTrat) => {
    setLoading(true);
    setError(null);
    console.log(id)
    try {
      const response = await api.put(`/relatorios/${id}`, data);
      setRelatorio(response.data);
      toast.success('Relatório atualizado com sucesso');

      await sleep(1500); // Espera de 1.5 segundos
      await fetchPastas(parentIdTrat);


      console.log('Relatório atualizado com sucesso:', response.data);
      return response.data;
    } catch (err) {
      setError(err);
      console.error('Erro ao atualizar relatório:', err);
    } finally {
      setLoading(false);
    }
  };

  // Função para deletar um relatório
  const deleteRelatorio = async (id, parentIdTrat) => {
    setLoading(true);
    setError(null);

    try {
      await api.delete(`/relatorios/${id}`);
      setRelatorio(null);
      toast.success('Relatório deletado com sucesso');
      console.log('Relatório deletado com sucesso');

      await sleep(1500); // Espera de 1.5 segundos
      await fetchPastas(parentIdTrat);

    } catch (err) {
      setError(err);
      console.error('Erro ao deletar relatório:', err);
    } finally {
      setLoading(false);
    }
  };

  return {
    createRelatorio,
    updateRelatorio,
    deleteRelatorio,
    relatorio,
    loading,
    error,
  };
};

export default useRelatorio;
