import { useState, useEffect, useContext } from "react";
import toastError from "../../errors/toastError";
import api from "../../services/api";
import { socketConnection } from "../../services/socket";
import { AuthContext } from "../../context/Auth/AuthContext";
import { toast } from "react-toastify";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";

const usePasta = () => {
  const [loading, setLoading] = useState(true);
  const [pastas, setPastas] = useState([]);
  const [error, setError] = useState(null);
  const { user } = useContext(AuthContext);
  const companyId = localStorage.getItem("companyId");
  const { departmentId } = useParams();
  const parentIdTrat = departmentId ? Number(departmentId) : 0;

  const fetchPastas = async (parentIdTrat) => {
    setLoading(true);
    try {
      // if(user.email && parentIdTrat && user.companyId || parentIdTrat === null){
        const { data } = await api.get("/pastas", {
          params: {
            parentId: parentIdTrat,
            companyId: user.companyId,
            usuario_id: user.email
          }
        });
        setPastas(data.pastas);
        // return data.pastas;


      // console.log(data.pastas)

    } catch (err) {
      // setError(err);
      toastError(err);
    } finally {
      setLoading(false);
    }
  };

  const createPasta = async (newPasta, parentIdTrat) => {
    try {
      await api.post("/pastas", newPasta);
      await fetchPastas(parentIdTrat);
      toast.success('Pasta criada com sucesso');
    } catch (err) {
      setError(err);
      toastError(err);
    }
  };

  const updatePasta = async (updatedPasta, parentIdTrat) => {
    try {
      await api.put(`/pastas/${updatedPasta.id}`, updatedPasta);
      await fetchPastas(parentIdTrat);
      toast.success('Pasta alterada com sucesso');
      console.log('finalizou o fetchPastas')
    } catch (err) {
      setError(err);
      toastError(err);
    }
  };

  const deletePasta = async (id, parentIdTrat) => {
    try {
      await api.delete(`/pastas/${id}`);
      await fetchPastas(parentIdTrat);
      toast.success('Pasta deletada com sucesso');
    } catch (err) {
      setError(err);
      toastError(err);
    }
  };

  // Hook useEffect para gerenciar a conexão do socket
  useEffect(() => {
    const socket = socketConnection({ companyId });

    const handlePastaUpdate = (data) => {
      // console.log(data.action)
      // console.log(data.userId)
      // console.log(user.email)
      if (data.action === "UPDATE_PASTAS" && data.userId === user.email) {
        // Verifica se o evento é para o usuário atual
        setPastas(data.pastaData.pastas);
      }
    };

    socket.on(`company-${companyId}-pastas`, handlePastaUpdate);

    // Limpeza do socket ao desmontar o componente
    return () => {
      socket.off(`company-${companyId}-pastas`, handlePastaUpdate);
      socket.disconnect();
    };
  }, [pastas, companyId, user.email]); // Dependências incluem o email do usuário

  useEffect(() => {
    fetchPastas(parentIdTrat); // Chama a função ao montar o componente
  }, [pastas]); // Dependência de companyId para garantir a atualização correta

  return {
    pastas,
    loading,
    error,
    createPasta,
    fetchPastas,
    updatePasta,
    deletePasta,
  };
};

export default usePasta;
