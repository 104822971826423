import { Container, Grid, makeStyles, Paper, Typography, useTheme } from '@material-ui/core';
import AccessAlarmIcon from '@material-ui/icons/AccessAlarm';
import AddIcon from "@material-ui/icons/Add";
import CallIcon from "@material-ui/icons/Call";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import HourglassEmptyIcon from "@material-ui/icons/HourglassEmpty";
import TimerIcon from '@material-ui/icons/Timer';
import ApexCharts from 'apexcharts';
import React, { useEffect, useRef, useState } from 'react';
import TableAttendantsStatus from '../../components/Dashboard/TableAttendantsStatus';
import Chart from './Chart';
import ApexChart from './NewChart';
import NewChart from './NewChart';
import NewChart2 from './NewChart2';

import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import TextField from "@material-ui/core/TextField";
import FormHelperText from "@material-ui/core/FormHelperText";

// ICONS
import SpeedIcon from "@material-ui/icons/Speed";
import Icon from "@material-ui/icons/";
import AssignmentIcon from "@material-ui/icons/Assignment";
import PersonIcon from "@material-ui/icons/Person";
import TodayIcon from '@material-ui/icons/Today';
import RecordVoiceOverIcon from "@material-ui/icons/RecordVoiceOver";
import ForumIcon from "@material-ui/icons/Forum";
import FilterListIcon from "@material-ui/icons/FilterList";
import ClearIcon from "@material-ui/icons/Clear";
import SendIcon from '@material-ui/icons/Send';
import MessageIcon from '@material-ui/icons/Message';

import { grey, blue } from "@material-ui/core/colors";
import { toast } from "react-toastify";

import ButtonWithSpinner from "../../components/ButtonWithSpinner";

import CardCounter from "../../components/Dashboard/CardCounter";
import { isArray } from "lodash";

import useDashboard from "../../hooks/useDashboard";
import useCompanies from "../../hooks/useCompanies";

import { isEmpty } from "lodash";
import moment from "moment";

const useStyles = makeStyles((theme) => ({
    fontInter: {
        fontFamily: '"Inter", sans-serif',
    },
    fixedHeightPaper: {
        padding: theme.spacing(2),
        display: "flex",
        flexDirection: "column",
        height: 240,
        overflowY: "auto",
        ...theme.scrollbarStyles,
    },
    container: {
        paddingTop: theme.spacing(4),
        paddingBottom: theme.spacing(4),

    },
    card: {
        padding: theme.spacing(2),
        display: "flex",
        overflow: "auto",
        flexDirection: "column",
        backgroundColor: theme.palette.type === 'dark' ? theme.palette.action.main : "#FFF",
        color: theme.palette.primary.main,
        cursor: "pointer",
        transition: 'transform 0.2s ease-in-out', // Adiciona a transição de transform
        transform: 'scale(1)', // Estado inicial
        // minWidth: "170px",
        // maxWidth: "300px",
        height: "80px",
        '&:hover': {
            transform: 'scale(1.05)', // Estado transformado no hover
        },
        [theme.breakpoints.down(475)]: {
            // maxWidth: "120px",
            // minWidth: "160px",
            height: "85px",
        }
    },
    cardCenter: {
        alignItems: "center"
    },
    alignRight: {
        textAlign: "right",
    },
    selectContainer: {
        width: "100%",
        textAlign: "left",
    },
    fixedHeightPaper: {
        padding: theme.spacing(2),
        display: "flex",
        flexDirection: "column",
        height: 240,
        overflowY: "auto",
        ...theme.scrollbarStyles,
    },
    typographySize: {
        fontSize: '1rem', 
        [theme.breakpoints.down(475)]: {
            fontSize: '0.80rem',
        },
    },
    customContainerChart: {
        padding: theme.spacing(2),
        display: "flex",
        flexDirection: "column",
    },
    customJustifyCard: {
        [theme.breakpoints.up('md')]: {
            justifyContent: 'space-around',
          },
          justifyContent: 'flex-start',
        },
}));


const NewDashboard = () => {

    const classes = useStyles();

    const themeAplication = useTheme();

    // const chartRef = useRef(null);

    // const [attendants, setAttendants] = useState([
    //     {
    //         name: 'João Silva',
    //         rating: 2.5,
    //         tickets: 15,
    //         avgSupportTime: 30, // tempo em minutos
    //         online: true
    //     },
    //     {
    //         name: 'Maria Oliveira',
    //         rating: 3,
    //         tickets: 20,
    //         avgSupportTime: 45, // tempo em minutos
    //         online: false
    //     },
    //     {
    //         name: 'Carlos Souza',
    //         rating: 1,
    //         tickets: 12,
    //         avgSupportTime: 25, // tempo em minutos
    //         online: true
    //     },
    //     {
    //         name: 'Ana Costa',
    //         rating: 3,
    //         tickets: 10,
    //         avgSupportTime: 15, // tempo em minutos
    //         online: false
    //     },
    //     {
    //         name: 'Pedro Santos',
    //         rating: 2,
    //         tickets: 8,
    //         avgSupportTime: 35, // tempo em minutos
    //         online: true
    //     }
    // ]);

    const [theme, setTheme] = useState(localStorage.getItem("preferredTheme"));

    const [counters, setCounters] = useState({});

    const [attendants, setAttendants] = useState([]);
    const [filterType, setFilterType] = useState(1);
    const [period, setPeriod] = useState(0);
    const [companyDueDate, setCompanyDueDate] = useState();
    const [dateFrom, setDateFrom] = useState(
        moment("1", "D").format("YYYY-MM-DD")
    );
    const [dateTo, setDateTo] = useState(moment().format("YYYY-MM-DD"));
    const [loading, setLoading] = useState(false);
    const { find } = useDashboard();
    const { finding } = useCompanies();
    useEffect(() => {
        async function firstLoad() {
            await fetchData();
        }
        setTimeout(() => {
            firstLoad();
        }, 1000);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    async function handleChangePeriod(value) {
        setPeriod(value);
    }

    async function handleChangeFilterType(value) {
        setFilterType(value);
        if (value === 1) {
            setPeriod(0);
        } else {
            setDateFrom("");
            setDateTo("");
        }
    }

    async function fetchData() {
        setLoading(true);

        let params = {};

        if (period > 0) {
            params = {
                days: period,
            };
        }

        if (!isEmpty(dateFrom) && moment(dateFrom).isValid()) {
            params = {
                ...params,
                date_from: moment(dateFrom).format("YYYY-MM-DD"),
            };
        }

        if (!isEmpty(dateTo) && moment(dateTo).isValid()) {
            params = {
                ...params,
                date_to: moment(dateTo).format("YYYY-MM-DD"),
            };
        }

        if (Object.keys(params).length === 0) {
            toast.error("Parametrize o filtro");
            setLoading(false);
            return;
        }

        const data = await find(params);



        setCounters(data.counters);
        if (isArray(data.attendants)) {
            setAttendants(data.attendants);
        } else {
            setAttendants([]);
        }

        setLoading(false);
    }

    useEffect(() => {
        async function fetchData() {
            await loadCompanies();
        }
        fetchData();
    }, [])
    //let companyDueDate = localStorage.getItem("companyDueDate");
    //const companyDueDate = localStorage.getItem("companyDueDate").toString();
    const companyId = localStorage.getItem("companyId");
    const loadCompanies = async () => {
        setLoading(true);
        try {
            const companiesList = await finding(companyId);
            setCompanyDueDate(moment(companiesList.dueDate).format("DD/MM/yyyy"));
        } catch (e) {
            console.log("🚀 Console Log : e", e);
            // toast.error("Não foi possível carregar a lista de registros");
        }
        setLoading(false);
    };

    function formatTime(minutes) {
        return moment()
            .startOf("day")
            .add(minutes, "minutes")
            .format("HH[h] mm[m]");
    }

    function renderFilters() {
        if (filterType === 1) {
            return (
                <>
                    <Grid item xs={12} sm={6} md={4}>
                        <TextField
                            label="Data Inicial"
                            type="date"
                            value={dateFrom}
                            onChange={(e) => setDateFrom(e.target.value)}
                            className={classes.fullWidth}
                            InputLabelProps={{
                                shrink: true,
                            }}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6} md={4}>
                        <TextField
                            label="Data Final"
                            type="date"
                            value={dateTo}
                            onChange={(e) => setDateTo(e.target.value)}
                            className={classes.fullWidth}
                            InputLabelProps={{
                                shrink: true,
                            }}
                        />
                    </Grid>
                </>
            );
        } else {
            return (
                <Grid item xs={12} sm={6} md={4}>
                    <FormControl className={classes.selectContainer}>
                        <InputLabel id="period-selector-label">Período</InputLabel>
                        <Select
                            labelId="period-selector-label"
                            id="period-selector"
                            value={period}
                            onChange={(e) => handleChangePeriod(e.target.value)}
                        >
                            <MenuItem value={0}>Nenhum selecionado</MenuItem>
                            <MenuItem value={3}>Últimos 3 dias</MenuItem>
                            <MenuItem value={7}>Últimos 7 dias</MenuItem>
                            <MenuItem value={15}>Últimos 15 dias</MenuItem>
                            <MenuItem value={30}>Últimos 30 dias</MenuItem>
                            <MenuItem value={60}>Últimos 60 dias</MenuItem>
                            <MenuItem value={90}>Últimos 90 dias</MenuItem>
                        </Select>
                        <FormHelperText>Selecione o período desejado</FormHelperText>
                    </FormControl>
                </Grid>
            );
        }
    }

    // useEffect(() => {
    //     const options = {
    //         series: [
    //             {
    //                 name: 'Clientes atendidos por mês',
    //                 //   data: [
    //                 //     { x: 'janeiro', y: 1292, goals: [{ name: 'Esperado', value: 1400, strokeHeight: 5, strokeColor: '#808080' }] },
    //                 //     { x: 'fevereiro', y: 4432, goals: [{ name: 'Esperado', value: 5400, strokeHeight: 5, strokeColor: '#808080' }] },
    //                 //     { x: 'março', y: 5423, goals: [{ name: 'Esperado', value: 5200, strokeHeight: 5, strokeColor: '#808080' }] },
    //                 //     { x: 'abril', y: 6653, goals: [{ name: 'Esperado', value: 6500, strokeHeight: 5, strokeColor: '#808080' }] },
    //                 //     { x: 'maio', y: 8133, goals: [{ name: 'Esperado', value: 6600, strokeHeight: 5, strokeColor: '#808080' }] },
    //                 //     { x: 'junho', y: 7132, goals: [{ name: 'Esperado', value: 7500, strokeHeight: 5, strokeColor: '#808080' }] },
    //                 //     { x: 'julho', y: 7332, goals: [{ name: 'Esperado', value: 8700, strokeHeight: 5, strokeColor: '#808080' }] },
    //                 //     { x: 'agosto', y: 6553, goals: [{ name: 'Esperado', value: 7300, strokeHeight: 5, strokeColor: '#808080' }] }
    //                 //   ]
    //                 // }
    //                 data: [
    //                     { x: 'janeiro', y: 1292 },
    //                     { x: 'fevereiro', y: 4432 },
    //                     { x: 'março', y: 5423 },
    //                     { x: 'abril', y: 6653 },
    //                     { x: 'maio', y: 8133 },
    //                     { x: 'junho', y: 7132 },
    //                     { x: 'julho', y: 7332 },
    //                     { x: 'agosto', y: 6553 }
    //                 ]
    //             }
    //         ],
    //         chart: {
    //             height: 200,
    //             type: 'bar'
    //         },
    //         plotOptions: {
    //             bar: {
    //                 columnWidth: '60%'
    //             }
    //         },
    //         colors: [theme === 'dark' ? '#FFF' : '#3CA4AC'],
    //         dataLabels: {
    //             enabled: false
    //         },
    //         legend: {
    //             show: true,
    //             customLegendItems: ['Actual', 'Expected'],
    //             markers: {
    //                 fillColors: ['#3CA4AC', '#FFF']
    //             }
    //         }
    //     };

    //     const chart = new ApexCharts(chartRef.current, options);
    //     chart.render();

    //     return () => chart.destroy(); // Limpa o gráfico quando o componente é desmontado
    // }, [chartRef, theme]);

    return (
        <>
            <Container maxWidth="lg" className={classes.container}>

                <Grid container spacing={3} justifyContent="flex-end">

                    <Grid container spacing={2} style={{ padding: '10px', justifyContent: 'space-between' }} >

                        {/* ATENDIMENTOS PENDENTES */}
                        <Grid item xs={6} sm={6} md={4} style={{ maxWidth: '350px', minWidth: '170px'}}>
                            <Paper
                                className={classes.card}
                                style={{ overflow: "hidden" }}
                                elevation={4}
                                
                            >
                                <Grid container spacing={2} className={classes.customJustifyCard}>
                                    <Grid item xs={8}>
                                        <Typography
                                            component="h3"
                                            className={classes.typographySize}
                                            color='primary'
                                        >
                                            Atd. Pendentes
                                        </Typography>
                                        <Grid item>
                                            <Typography
                                                component="h3"
                                                variant="h5"
                                            >
                                                {counters.supportPending}
                                            </Typography>
                                        </Grid>
                                    </Grid>
                                    <Grid item xs={2}>
                                        <CallIcon
                                            style={{
                                                fontSize: 'clamp(30px, 35px, 40px)',
                                            }}
                                        />
                                    </Grid>
                                </Grid>
                            </Paper>
                        </Grid>

                        {/* ATENDIMENTOS ACONTECENDO */}
                        <Grid item xs={6} sm={6} md={4} style={{ maxWidth: '350px', minWidth: '170px'}} >
                            <Paper
                                className={classes.card}
                                style={{ overflow: "hidden" }}
                                elevation={6}
                            >
                                <Grid container spacing={3} className={classes.customJustifyCard}>
                                    <Grid item xs={8}>
                                        <Typography
                                            component="h3"
                                            className={classes.typographySize}
                                        >
                                            Atd. Agora
                                        </Typography>
                                        <Grid item>
                                            <Typography
                                                component="h3"
                                                variant="h5"
                                            >
                                                {counters.supportHappening}
                                            </Typography>
                                        </Grid>
                                    </Grid>
                                    <Grid item xs={2}>
                                        <HourglassEmptyIcon
                                            style={{
                                                fontSize: 'clamp(30px, 35px, 40px)',
                                            }}
                                        />
                                    </Grid>
                                </Grid>
                            </Paper>
                        </Grid>


                        {/* ATENDIMENTOS REALIZADOS */}
                        <Grid item xs={6} sm={6} md={4} style={{ maxWidth: '350px', minWidth: '170px'}} >
                            <Paper
                                className={classes.card}
                                style={{ overflow: "hidden" }}
                                elevation={6}
                            >
                                <Grid container spacing={3} className={classes.customJustifyCard}>
                                    <Grid item xs={8}>
                                        <Typography
                                            component="h3"
                                            className={classes.typographySize}

                                        >
                                            Finalizados
                                        </Typography>
                                        <Grid item>
                                            <Typography
                                                component="h3"
                                                variant="h5"
                                            >
                                                {counters.supportFinished}
                                            </Typography>
                                        </Grid>
                                    </Grid>
                                    <Grid item xs={2}>
                                        <CheckCircleIcon
                                            style={{
                                                fontSize: 'clamp(30px, 35px, 40px)',
                                            }}
                                        />
                                    </Grid>
                                </Grid>
                            </Paper>
                        </Grid>

                        {/* NOVOS CONTATOS */}
                        <Grid item xs={6} sm={6} md={4} style={{ maxWidth: '350px', minWidth: '170px'}} >
                            <Paper
                                className={classes.card}
                                style={{ overflow: "hidden" }}
                                elevation={6}
                            >
                                <Grid container spacing={3} className={classes.customJustifyCard}>
                                    <Grid item xs={8}>
                                        <Typography
                                            component="h3"
                                            className={classes.typographySize}
                                        >
                                            Novos Contatos
                                        </Typography>
                                        <Grid item>
                                            <Typography
                                                component="h3"
                                                variant="h5"
                                            >
                                                {counters.leads}
                                            </Typography>
                                        </Grid>
                                    </Grid>
                                    <Grid item xs={2}>
                                        <AddIcon
                                            style={{
                                                fontSize: 'clamp(30px, 35px, 40px)',
                                            }}
                                        />
                                    </Grid>
                                </Grid>
                            </Paper>
                        </Grid>

                        {/* T.M. DE ATENDIMENTO */}
                        <Grid item xs={6} sm={6} md={4} style={{ maxWidth: '350px', minWidth: '170px'}} >
                            <Paper
                                className={classes.card}
                                style={{ overflow: "hidden" }}
                                elevation={6}
                            >
                                <Grid container spacing={3} className={classes.customJustifyCard}>
                                    <Grid item xs={8}>
                                        <Typography
                                            component="h3"
                                            className={classes.typographySize}
                                        >
                                            T.M. de Atendimento
                                        </Typography>
                                        <Grid item>
                                            <Typography
                                                component="h3"
                                                variant="h5"
                                            >
                                                0
                                            </Typography>
                                        </Grid>
                                    </Grid>
                                    <Grid item xs={2}>
                                        <AccessAlarmIcon
                                            style={{
                                                fontSize: 'clamp(30px, 35px, 40px)',
                                            }}
                                        />
                                    </Grid>
                                </Grid>
                            </Paper>
                        </Grid>


                        {/* T.M. DE ESPERA */}
                        <Grid item xs={6} sm={6} md={4} style={{ maxWidth: '350px', minWidth: '170px'}} >
                            <Paper
                                className={classes.card}
                                style={{ overflow: "hidden" }}
                                elevation={6}
                            >
                                <Grid container spacing={3} className={classes.customJustifyCard}>
                                    <Grid item xs={8}>
                                        <Typography
                                            component="h3"
                                            className={classes.typographySize}
                                        >
                                            T.M. de Espera
                                        </Typography>
                                        <Grid item>
                                            <Typography
                                                component="h3"
                                                variant="h5"
                                            >
                                                {0}
                                            </Typography>
                                        </Grid>
                                    </Grid>
                                    <Grid item xs={2}>
                                        <TimerIcon
                                            style={{
                                                fontSize: 'clamp(30px, 35px, 40px)',
                                            }}
                                        />
                                    </Grid>
                                </Grid>
                            </Paper>
                        </Grid>
                    </Grid>

                    {/* FILTROS */}
                    <Grid item xs={12} sm={6} md={4}>
                        <FormControl className={classes.selectContainer}>
                            <InputLabel id="period-selector-label">Tipo de Filtro</InputLabel>
                            <Select
                                labelId="period-selector-label"
                                value={filterType}
                                onChange={(e) => handleChangeFilterType(e.target.value)}
                            >
                                <MenuItem value={1}>Filtro por Data</MenuItem>
                                <MenuItem value={2}>Filtro por Período</MenuItem>
                            </Select>
                            <FormHelperText>Selecione o período desejado</FormHelperText>
                        </FormControl>
                    </Grid>

                    {renderFilters()}

                    {/* BOTAO FILTRAR */}
                    <Grid 
                        item xs={12} 
                        className={classes.alignRight}
                    >
                        <ButtonWithSpinner
                            loading={loading}
                            onClick={() => fetchData()}
                            variant="contained"
                            style={{
                                color: themeAplication.palette.light.main,
                                background: themeAplication.palette.primary.main
                            }}
                        >
                            Filtrar
                        </ButtonWithSpinner>
                    </Grid>

                    {/* Gráfico 1 */}
                    <Grid item xs={12} tyle={{ marginBlock: 40 }}>
                        <Paper className={classes.customContainerChart}>
                            <NewChart2 />
                        </Paper>
                    </Grid>

                    {/* <Grid item xs={12}>
                        <Paper className={classes.fixedHeightPaper}>
                            <Chart />
                        </Paper>
                    </Grid> */}

                    {/* USUARIOS ONLINE */}
                    <Grid item xs={12}>
                        {attendants.length ? (
                            <TableAttendantsStatus
                                attendants={attendants}
                                loading={loading}
                            />
                        ) : null}
                    </Grid>
                </Grid >
            </Container>
        </>
    );
};

export default NewDashboard;