import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Modal from '@mui/material/Modal';
import Typography from '@mui/material/Typography';
import React, { useContext } from 'react';
import api from '../../services/api';
import usePasta from '../../hooks/usePasta/usePasta';
import { AuthContext } from '../../context/Auth/AuthContext';
import { useParams } from 'react-router-dom/cjs/react-router-dom.min';
import useRelatorio from '../../hooks/useRelatorio/useRelatorio';

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: { xs: '90%', sm: 800 }, // Largura responsiva
    bgcolor: 'background.paper',
    border: 'none',
    boxShadow: 24,
    p: 4,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    gap: 2,
    borderRadius: '10px',
};

export default function ModalRowTableDepartmentsEdit({ openModalDelete, setOpenModalDelete, fileType, departmentsDatabase, setDepartmentsDatabase, rowIdCell, folderName, rowTextCell }) {
    const { departmentId } = useParams();
    const { deleteRelatorio } = useRelatorio()
    const parentIdTrat = departmentId ? Number(departmentId) : 0;
  
    const {deletePasta} = usePasta()
    async function handleDeleteFile() {
        if(fileType === 'relatorio'){
            try {
                // Fazendo a requisição DELETE
                await deleteRelatorio(parseInt(rowIdCell.replace('Relatório','')), parentIdTrat)
                // await api.delete(`/relatorios/${parseInt(rowIdCell.replace('Relatório',''))}`);
                console.log('Relatório deletado com sucesso');
    
                // Atualizando o estado local após a exclusão
                const filteredDepartmentsData = departmentsDatabase.filter((file) => file.id !== rowIdCell);
                setDepartmentsDatabase(filteredDepartmentsData);
    
                setOpenModalDelete(false);
            } catch (error) {
                console.error('Erro ao deletar o relatório:', error);
            }
        }else{
            try {
                // Fazendo a requisição DELETE
                await deletePasta(parseInt(rowIdCell.replace('Pasta','')), parentIdTrat);
                // await api.delete(`/pastas/${parseInt(rowIdCell.replace('Pasta',''))}`);
                // console.log('Pasta deletada com sucesso');
    
                // Atualizando o estado local após a exclusão
                const filteredDepartmentsData = departmentsDatabase.filter((file) => file.id !== rowIdCell);
                setDepartmentsDatabase(filteredDepartmentsData);
    
                setOpenModalDelete(false);
            } catch (error) {
                console.error('Erro ao deletar a pasta:', error);
            }
        }
       
    }

    return (
        <div>
            <Modal
                open={openModalDelete}
                onClose={() => setOpenModalDelete(false)}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={style}>
                    <Typography id="modal-modal-title" variant="h6" component="h2" sx={{ textAlign: 'center' }}>
                        {fileType === 'relatorio' ? `Deseja realmente excluir o relatório ${folderName}?` : `Deseja realmente excluir a pasta ${folderName}?`}
                    </Typography>

                    <Button
                        onClick={handleDeleteFile}
                        variant="contained"
                        color="error"
                        sx={{
                            width: '50%',
                            fontSize: {
                                xs: '0.75rem',
                                sm: '1rem',
                            },
                            '&:hover': {
                                opacity: .8,
                                backgroundColor: "#D93232"
                            },
                        }}
                        type="submit"
                    >
                        Deletar
                    </Button>

                    <Button
                        onClick={() => setOpenModalDelete(false)}
                        variant="contained"
                        color="inerit"
                        sx={{
                            width: '50%',
                            fontSize: {
                                xs: '0.75rem',
                                sm: '1rem',
                            }
                        }}
                        type="submit"
                    >
                        Cancelar
                    </Button>

                </Box>
            </Modal>
        </div>
    );
}
