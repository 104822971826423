import { Button } from "@material-ui/core";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import ArrowDownwardIcon from "@material-ui/icons/ArrowDownward";
import ArrowUpwardIcon from "@material-ui/icons/ArrowUpward";
import FolderIcon from "@material-ui/icons/Folder";
import InsertDriveFileIcon from "@material-ui/icons/InsertDriveFile";
import RemoveIcon from "@material-ui/icons/Remove";
import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import departmentsData from "../../database/departmentsData";
import EditOrDeleteRow from "../EditOrDeleteRow";

const StyledTableCell = withStyles((theme) => ({
    head: {
        color: theme.palette.common.black,
    },
    body: {
        fontSize: 14,
    },
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
    root: {
        "&:hover": {
            backgroundColor: theme.palette.action.hover,
        },
    },
}))(TableRow);

const useStyles = makeStyles({
    table: {
        minWidth: 700,
        marginTop: "16px"
    },
    pointerCell: {
        cursor: "pointer",
    },
});

export default function TableDepartments() {

    const classes = useStyles();

    const [departmentsDatabase, setDepartmentsDatabase] = useState(departmentsData);

    const [departmentsDataOrdered, setDepartmentsDataOrdered] = useState([]);

    const [isAscending, setIsAscending] = useState(true);

    const [currentIcon, setCurrentIcon] = useState(<ArrowDownwardIcon />);

    const [selectedFilter, setSelectedFilter] = useState("");

    const [openEditAndDeleteButtons, setOpenEditAndDeleteButtons] = useState(false);

    const [hoveredRowId, setHoveredRowId] = useState(null);

    const history = useHistory();

    const comparingValues = (a, b, isAscending, isDate = false) => {
        if (isDate) {
            // Comparando como datas
            const dateA = new Date(a);
            const dateB = new Date(b);
            return isAscending ? dateA - dateB : dateB - dateA;
        } else {
            // Comparando como strings   
            return isAscending ? a.localeCompare(b) : b.localeCompare(a);
        }
    }

    const handleSortByName = () => {
        const sortedNames = [...departmentsDatabase].sort((a, b) => comparingValues(a.name, b.name, isAscending));
        setDepartmentsDataOrdered(sortedNames);
        setIsAscending(!isAscending);
        setCurrentIcon(isAscending ? <ArrowUpwardIcon /> : <ArrowDownwardIcon />);
    };

    const handleSortByOwner = () => {
        const sortedNames = [...departmentsDatabase].sort((a, b) => comparingValues(a.owner, b.owner, isAscending));
        setDepartmentsDataOrdered(sortedNames);
        setIsAscending(!isAscending);
        setCurrentIcon(isAscending ? <ArrowUpwardIcon /> : <ArrowDownwardIcon />);
    };

    const handleSortByRefrashed = () => {
        const sortedDates = [...departmentsDatabase].sort((a, b) => comparingValues(a.refreshed, b.refreshed, isAscending));
        setDepartmentsDataOrdered(sortedDates);
        setIsAscending(!isAscending);
        setCurrentIcon(isAscending ? <ArrowUpwardIcon /> : <ArrowDownwardIcon />);
    };

    const handleSortByNextRefrashed = () => {
        const sortedDates = [...departmentsDatabase].sort((a, b) => comparingValues(a.nextRefreshed, b.nextRefreshed, isAscending));
        setDepartmentsDataOrdered(sortedDates);
        setIsAscending(!isAscending);
        setCurrentIcon(isAscending ? <ArrowUpwardIcon /> : <ArrowDownwardIcon />);
    };

    const handleFilterClick = (filterType) => {
        setSelectedFilter(filterType); // Atualiza o estado com o filtro selecionado
    };

    const getButtonStyle = (filterType) => {
        // Se o botão for o selecionado, aplique uma cor mais escura
        return selectedFilter === filterType ? { backgroundColor: "#ccc" } : {};
    };

    function handleNavigateToDepartmentId(departmentId) {
        history.push(`/departments/${departmentId}`);
    }

    return (
        <TableContainer >
            <Table className={classes.table} aria-label="department table">
                <TableHead>
                    <TableRow>
                        <StyledTableCell > <InsertDriveFileIcon color="action" /> </StyledTableCell>
                        <StyledTableCell >
                            <Button
                                style={getButtonStyle("name")}
                                endIcon={currentIcon}
                                onClick={() => {
                                    handleFilterClick("name");
                                    handleSortByName();
                                }}
                            >
                                Nome
                            </Button>
                        </StyledTableCell>
                        <StyledTableCell >Type</StyledTableCell>
                        <StyledTableCell >Task</StyledTableCell>
                        <StyledTableCell >
                            <Button
                                style={getButtonStyle("owner")}
                                endIcon={currentIcon}
                                onClick={() => {
                                    handleFilterClick("owner")
                                    handleSortByOwner()
                                }}
                            >
                                Owner
                            </Button>
                        </StyledTableCell>
                        <StyledTableCell >
                            <Button
                                style={getButtonStyle("refreshed")}
                                endIcon={currentIcon}
                                onClick={() => {
                                    handleFilterClick("refreshed")
                                    handleSortByRefrashed()
                                }}
                            >
                                Refreshed
                            </Button>
                        </StyledTableCell>
                        <StyledTableCell>
                            <Button
                                style={getButtonStyle("newRefreshed")}
                                endIcon={currentIcon}
                                onClick={() => {
                                    handleFilterClick("newRefreshed")
                                    handleSortByNextRefrashed()
                                }}
                            >
                                Next Refreshed
                            </Button>
                        </StyledTableCell>
                        <StyledTableCell >Endorsement</StyledTableCell>
                        <StyledTableCell >Sensitivity</StyledTableCell>
                    </TableRow>
                </TableHead>

                <TableBody>
                    {

                        departmentsDataOrdered.length ? departmentsDataOrdered.map((department) => (
                            <StyledTableRow key={department.id}>
                                <StyledTableCell
                                    onClick={() => handleNavigateToDepartmentId(department.id)}
                                    className={classes.pointerCell}
                                >
                                    <FolderIcon color="action" />
                                </StyledTableCell>
                                <StyledTableCell
                                    component="th"
                                    scope="row"
                                    classes={classes.hoverStyle}
                                    onMouseEnter={() => setHoveredRowId(department.id)}
                                    onMouseLeave={() => {
                                        setHoveredRowId(null)
                                        setOpenEditAndDeleteButtons(false)
                                    }
                                    } >

                                    <EditOrDeleteRow
                                        rowTextCell={department.name}
                                        rowIdCell={department.id}
                                        hoveredRowId={hoveredRowId}
                                        openEditAndDeleteButtons={openEditAndDeleteButtons}
                                        setOpenEditAndDeleteButtons={setOpenEditAndDeleteButtons}
                                        departmentsDatabase={departmentsDataOrdered}
                                        setDepartmentsDatabase={setDepartmentsDataOrdered}
                                    />

                                </StyledTableCell>
                                <StyledTableCell>

                                </StyledTableCell>
                                <StyledTableCell>
                                    {department.task ? department.task : <RemoveIcon />}
                                </StyledTableCell>
                                <StyledTableCell>
                                    {department.owner}
                                </StyledTableCell>
                                <StyledTableCell>
                                    {department.refreshed ? department.refreshed : <RemoveIcon />}
                                </StyledTableCell>
                                <StyledTableCell>
                                    {department.nextRefreshed ? department.nextRefreshed : <RemoveIcon />}
                                </StyledTableCell>
                                <StyledTableCell>
                                    {department.endorsement ? department.endorsement : <RemoveIcon />}
                                </StyledTableCell>
                                <StyledTableCell>
                                    {department.sensitivity ? department.sensitivity : <RemoveIcon />}
                                </StyledTableCell>
                            </StyledTableRow>
                        ))

                            :

                            departmentsDatabase.map((department) => (
                                <StyledTableRow key={department.id}>
                                    <StyledTableCell
                                        onClick={() => handleNavigateToDepartmentId(department.id)}
                                        className={classes.pointerCell}
                                    >
                                        <FolderIcon color="action" />
                                    </StyledTableCell>

                                    <StyledTableCell
                                        component="th"
                                        scope="row"
                                        classes={classes.hoverStyle}
                                        onMouseEnter={() => setHoveredRowId(department.id)}
                                        onMouseLeave={() => {
                                            setHoveredRowId(null)
                                            setOpenEditAndDeleteButtons(false)
                                        }
                                        }
                                    >

                                        <EditOrDeleteRow
                                            rowTextCell={department.name}
                                            rowIdCell={department.id}
                                            hoveredRowId={hoveredRowId}
                                            openEditAndDeleteButtons={openEditAndDeleteButtons}
                                            setOpenEditAndDeleteButtons={setOpenEditAndDeleteButtons}
                                            departmentsDatabase={departmentsDataOrdered}
                                            setDepartmentsDatabase={setDepartmentsDataOrdered}
                                        />

                                    </StyledTableCell>
                                    <StyledTableCell>
                                        {department.type}
                                    </StyledTableCell>
                                    <StyledTableCell>
                                        {department.task ? department.task : <RemoveIcon />}
                                    </StyledTableCell>
                                    <StyledTableCell>
                                        {department.owner}
                                    </StyledTableCell>
                                    <StyledTableCell>
                                        {department.refreshed ? department.refreshed : <RemoveIcon />}
                                    </StyledTableCell>
                                    <StyledTableCell>
                                        {department.nextRefreshed ? department.nextRefreshed : <RemoveIcon />}
                                    </StyledTableCell>
                                    <StyledTableCell>
                                        {department.endorsement ? department.endorsement : <RemoveIcon />}
                                    </StyledTableCell>
                                    <StyledTableCell>
                                        {department.sensitivity ? department.sensitivity : <RemoveIcon />}
                                    </StyledTableCell>
                                </StyledTableRow>
                            ))}
                </TableBody>
            </Table>
        </TableContainer>
    )
}
