import { Grid } from "@material-ui/core";
import imgBanerAscview from "../../assets/fundo_Ascview.png"

export function AscviewHome (){
    return(
        <div>
           <Grid 
                style={{
                    width: '100vw',
                    backgroundImage: `url(${imgBanerAscview})`, // Corrige para usar url()
                    backgroundSize: 'cover', // Ajusta a imagem para cobrir a área do grid
                    backgroundPosition: 'center', // Centraliza a imagem
                    height: '150px',
                    marginTop: '1px'
                }}
            >

            </Grid>
        </div>
    )
}