import React, { useState, useEffect } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import api from '../../services/api';

const FolderView = ({ parentId }) => {
  const [folders, setFolders] = useState([]);
  const [loading, setLoading] = useState(true);
  const { departmentId } = useParams(); // Obtém o ID da URL

  // Verifica se id é undefined e usa parentId se necessário
  const parentIdTrat = departmentId ? Number(departmentId) : parentId;

  const history = useHistory(); // Hook para navegação

  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true);
        const response = await api.get("/pastas", {
          params: { parentId: parentIdTrat } // Usa parentIdTrat aqui
        });
        setFolders(response.data.pastas);
        setLoading(false);
      } catch (error) {
        console.error('Erro ao carregar pastas:', error);
        setLoading(false);
      }
    };

    fetchData();
  }, [parentIdTrat]); // Adiciona parentIdTrat na dependência

  if (loading) {
    return <div>Carregando...</div>;
  }

  return (
    <div>
      <h2>Pastas</h2>
      <ul>
        {folders.map((folder) => (
          <Folder 
            key={folder.id} 
            folder={folder} 
            onClick={() => history.push(`/departments/${folder.id}`)} // Navegação ao clicar na pasta
          />
        ))}
      </ul>
    </div>
  );
};

const Folder = ({ folder, onClick }) => {
  return (
    <li>
      <div onClick={onClick} style={{ cursor: 'pointer' }}>
        {folder.nome}
      </div>
    </li>
  );
};

export default FolderView;
