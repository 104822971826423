
const departmentsData = [
    {
        "id": 1,
        "icon": "icone",
        "name": "Recursos Humanos",
        "type": "Prioritária",
        "task": "Completar projeto X",
        "owner": "Usuário Teste",
        "refreshed": "",
        "nextRefreshed": "2024-09-15",
        "endorsement": "Aprovado",
        "sensitivity": "",
        "reports": [
            {
                "id": 1,
                "title": "Pagamentos",
                "description": "Relatorio mensal de prestação de contas",
                "date": "15-09-2024",
                "report": "eed to be sure there isn't anything embarrassing hidden in the middle of text. All the Lorem Ipsum generators on the Internet tend to repeat predefined chunks as necessary, making this the first true generator on the Internet. It uses a dictionary of over 200 Latin words, combined with a handful of model sentence structures, to generate Lorem Ipsum which looks reasonable. The generated Lorem Ipsum is therefore always free from repetition, injected humour, or non-characteristic words etc."
            },
            {
                "id": 2,
                "title": "Pontos",
                "description": "Relatorio mensal de folha de pontos",
                "date": "18-09-2024",
                "report": "eed to be sure there isn't anything embarrassing hidden in the middle of text. All the Lorem Ipsum generators on the Internet tend to repeat predefined chunks as necessary, making this the first true generator on the Internet. It uses a dictionary of over 200 Latin words, combined with a handful of model sentence structures, to generate Lorem Ipsum which looks reasonable. The generated Lo be sure there isn't anything embarrassing hidden in the middle of text. All the Lorem Ipsum generators on the Internet tend to repeat predefined chunks as necessary, making this the first true generator on the Irem Ipsum is therefore always free from repetition, injected humour, or non-characteristic words etc."
            },
            {
                "id": 3,
                "title": "Reuniões",
                "description": "Relatorio mensal de reuniões mensais",
                "date": "10-09-2024",
                "report": "eed to be sure there isn't anything embarrassing hidden in the middle of text. All the Lorem Ipsum generators on the Internet tend to repeat predefined chunks as necessary, making this the first true generator on the Internet. It uses a dictionary of over 200 Latin words, combined with a handful of model sentence structures, to generate Lorem Ipsum which looks reasonable. The generated Lo be sure there isn't anything embarrassing hidden in the middle of text. All the Lorem Ipsum generators on the Internet tend to repeat predefined chunks as necessary, making this the first true generator on the Irem Ipsum is therefore always free from repetition, injected humour, or non-characteristic words etc."
            },

        ]
    },
    {
        "id": 2,
        "icon": "icone",
        "name": "Frente de Caixa",
        "type": "Prioritária",
        "task": "Completar projeto y",
        "owner": "Usuário Teste",
        "refreshed": "2024-09-13",
        "nextRefreshed": "",
        "endorsement": "Em análise",
        "sensitivity": "Medio",
        "reports": [
            {
                "id": 1,
                "title": "Funcionarios",
                "description": "Lista de funcionarios",
                "date": "01-09-2024",
                "report": "eed to be sure there isn't anything embarrassing hidden in the middle of text. All the Lorem Ipsum generators on the Internet tend to repeat predefined chunks as necessary, making this the first true generator on the Internet. It uses a dictionary of over 200 Latin words, combined with a handful of model sentence structures, to generate Lorem Ipsum which looks reasonable. The generated Lo be sure there isn't anything embarrassing hidden in the middle of text. All the Lorem Ipsum generators on the Internet tend to repeat predefined chunks as necessary, making this the first true generator on the Irem Ipsum is therefore always free from repetition, injected humour, or non-characteristic words etc."
            },
            {
                "id": 2,
                "title": "Pontos",
                "description": "Relatorio mensal de folha de pontos",
                "date": "18-09-2024",
                "report": "eed to be sure there isn't anything embarrassing hidden in the middle of text. All the Lorem Ipsum generators on the Internet tend to repeat predefined chunks as necessary, making this the first true generator on the Internet. It uses a dictionary of over 200 Latin words, combined with a handful of model sentence structures, to generate Lorem Ipsum which looks reasonable. The generated Lo be sure there isn't anything embarrassing hidden in the middle of text. All the Lorem Ipsum generators on the Internet tend to repeat predefined chunks as necessary, making this the first true generator on the Irem Ipsum is therefore always free from repetition, injected humour, or non-characteristic words etc."
            },
            {
                "id": 3,
                "title": "Atividades",
                "description": "Relatorio mensal de atividades pendentes",
                "date": "10-09-2024",
                "report": "eed to be sure there isn't anything embarrassing hidden in the middle of text. All the Lorem Ipsum generators on the Internet tend to repeat predefined chunks as necessary, making this the first true generator on the Internet. It uses a dictionary of over 200 Latin words, combined with a handful of model sentence structures, to generate Lorem Ipsum which looks reasonable. The generated Lo be sure there isn't anything embarrassing hidden in the middle of text. All the Lorem Ipsum generators on the Internet tend to repeat predefined chunks as necessary, making this the first true generator on the Irem Ipsum is therefore always free from repetition, injected humour, or non-characteristic words etc."
            },

        ]
    },
    {
        "id": 3,
        "icon": "icone",
        "name": "FLV",
        "type": "Prioritária",
        "task": "Completar projeto z",
        "owner": "Usuário Teste",
        "refreshed": "2024-09-11",
        "nextRefreshed": "2024-09-20",
        "endorsement": "Reprovado",
        "sensitivity": "",
        "reports": [
            {
                "id": 1,
                "title": "Produtos",
                "description": "Lista de produtos em estoque",
                "date": "15-09-2024",
                "report": "eed to be sure there isn't anything embarrassing hidden in the middle of text. All the Lorem Ipsum generators on the Internet tend to repeat predefined chunks as necessary, making this the first true generator on the Internet. It uses a dictionary of over 200 Latin words, combined with a handful of model sentence structures, to generate Lorem Ipsum which looks reasonable. The generated Lo be sure there isn't anything embarrassing hidden in the middle of text. All the Lorem Ipsum generators on the Internet tend to repeat predefined chunks as necessary, making this the first true generator on the Irem Ipsum is therefore always free from repetition, injected humour, or non-characteristic words etc."
            },
            {
                "id": 2,
                "title": "Quebras",
                "description": "Relatorio quebras mensais",
                "date": "18-09-2024",
                "report": "eed to be sure there isn't anything embarrassing hidden in the middle of text. All the Lorem Ipsum generators on the Internet tend to repeat predefined chunks as necessary, making this the first true generator on the Internet. It uses a dictionary of over 200 Latin words, combined with a handful of model sentence structures, to generate Lorem Ipsum which looks reasonable. The generated Lo be sure there isn't anything embarrassing hidden in the middle of text. All the Lorem Ipsum generators on the Internet tend to repeat predefined chunks as necessary, making this the first true generator on the Irem Ipsum is therefore always free from repetition, injected humour, or non-characteristic words etc."
            },
            {
                "id": 3,
                "title": "Reuniões",
                "description": "Relatorio mensal de reuniões mensais",
                "date": "10-09-2024",
                "report": "eed to be sure there isn't anything embarrassing hidden in the middle of text. All the Lorem Ipsum generators on the Internet tend to repeat predefined chunks as necessary, making this the first true generator on the Internet. It uses a dictionary of over 200 Latin words, combined with a handful of model sentence structures, to generate Lorem Ipsum which looks reasonable. The generated Lo be sure there isn't anything embarrassing hidden in the middle of text. All the Lorem Ipsum generators on the Internet tend to repeat predefined chunks as necessary, making this the first true generator on the Irem Ipsum is therefore always free from repetition, injected humour, or non-characteristic words etc."
            },

        ]
    },
    {
        "id": 4,
        "icon": "icone",
        "name": "Prevenção",
        "type": "Prioritária",
        "task": "Testar tabela",
        "owner": "Adm",
        "refreshed": "",
        "nextRefreshed": "",
        "endorsement": "Reprovado",
        "sensitivity": "Alto",
        "reports": [
            {
                "id": 1,
                "title": "Clientes",
                "description": "Relatorio mensal de clientes PJs atendidos",
                "date": "11-09-2024",
                "report": "eed to be sure there isn't anything embarrassing hidden in the middle of text. All the Lorem Ipsum generators on the Internet tend to repeat predefined chunks as necessary, making this the first true generator on the Internet. It uses a dictionary of over 200 Latin words, combined with a handful of model sentence structures, to generate Lorem Ipsum which looks reasonable. The generated Lo be sure there isn't anything embarrassing hidden in the middle of text. All the Lorem Ipsum generators on the Internet tend to repeat predefined chunks as necessary, making this the first true generator on the Irem Ipsum is therefore always free from repetition, injected humour, or non-characteristic words etc."
            },
            {
                "id": 2,
                "title": "Perdas",
                "description": "Relatorio perdas mensais",
                "date": "12-09-2024",
                "report": "eed to be sure there isn't anything embarrassing hidden in the middle of text. All the Lorem Ipsum generators on the Internet tend to repeat predefined chunks as necessary, making this the first true generator on the Internet. It uses a dictionary of over 200 Latin words, combined with a handful of model sentence structures, to generate Lorem Ipsum which looks reasonable. The generated Lo be sure there isn't anything embarrassing hidden in the middle of text. All the Lorem Ipsum generators on the Internet tend to repeat predefined chunks as necessary, making this the first true generator on the Irem Ipsum is therefore always free from repetition, injected humour, or non-characteristic words etc."
            },
            {
                "id": 3,
                "title": "Horarios",
                "description": "Escala de funcionarios",
                "date": "10-010-2024",
                "report": "eed to be sure there isn't anything embarrassing hidden in the middle of text. All the Lorem Ipsum generators on the Internet tend to repeat predefined chunks as necessary, making this the first true generator on the Internet. It uses a dictionary of over 200 Latin words, combined with a handful of model sentence structures, to generate Lorem Ipsum which looks reasonable. The generated Lo be sure there isn't anything embarrassing hidden in the middle of text. All the Lorem Ipsum generators on the Internet tend to repeat predefined chunks as necessary, making this the first true generator on the Irem Ipsum is therefore always free from repetition, injected humour, or non-characteristic words etc."
            },

        ]
    }
];

export default departmentsData;