import React, { useContext, useState, useEffect } from 'react';
import { Button, makeStyles, Table, TableBody, TableContainer, TableHead, TableRow, withStyles } from '@material-ui/core';
import TableCell from "@material-ui/core/TableCell";
import ArrowDownwardIcon from "@material-ui/icons/ArrowDownward";
import ArrowUpwardIcon from "@material-ui/icons/ArrowUpward";
import FolderIcon from "@material-ui/icons/Folder";
import InsertDriveFileIcon from "@material-ui/icons/InsertDriveFile";
import { useParams, useHistory } from 'react-router-dom';
import api from '../../services/api';
import HeaderDepartment from '../HeaderDepartment';
import userEvent from '@testing-library/user-event';
import { AuthContext } from '../../context/Auth/AuthContext';
import EditOrDeleteRow from '../EditOrDeleteRow';
import usePasta from '../../hooks/usePasta/usePasta';
import { toast } from 'react-toastify';
import { FaChartColumn } from 'react-icons/fa6';
import Barchart from './barchart.svg';

const StyledTableCell = withStyles((theme) => ({
  head: {
    color: theme.palette.common.black,
  },
  body: {
    fontSize: 14,
  },
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
  root: {
    "&:hover": {
      backgroundColor: theme.palette.action.hover,
    },
  },
}))(TableRow);

const useStyles = makeStyles({
  table: {
    minWidth: 700,
    marginTop: "16px"
  },
  pointerCell: {
    cursor: "pointer",
  },
});

const DepartmentDetails = () => {
  const classes = useStyles();
  const [folders, setFolders] = useState([]);
  const [reports, setReports] = useState([]);
  const [loading, setLoading] = useState(true);
  const [isAscending, setIsAscending] = useState(true);
  const { pastas, error, fetchPastas } = usePasta();
  const [departmentsDataOrdered, setDepartmentsDataOrdered] = useState([]);
  const [tokenApiPowerBi, setTokenApiPowerBi] = useState([]);
  const [currentIcon, setCurrentIcon] = useState(<ArrowDownwardIcon />);
  const [selectedFilter, setSelectedFilter] = useState("");
  const [parentDepartmentName, setParentDepartmentName] = useState("");
  const [config, setConfig] = useState({
    clientId: '',
    authorityUrl: 'https://login.microsoftonline.com/organizations/',
    tenantId: '',
    pbiUsername: '',
    pbiPassword: '',
    clientSecret: '',
    authenticationMode: 'MasterUser',
    scopeBase: 'https://analysis.windows.net/powerbi/api/.default',
    workspaceId: ''
});
  const [openEditAndDeleteButtons, setOpenEditAndDeleteButtons] = useState(false);
  const [hoveredRowId, setHoveredRowId] = useState(null);
  const { departmentId } = useParams();
  const parentIdTrat = departmentId ? Number(departmentId) : 0;
  const history = useHistory();
  const { user, handleLogout } = useContext(AuthContext);
  const companyId = localStorage.getItem("companyId");


  useEffect(() => {
    if (user && (!user.super && user.profile !== "admin")) {
      // console.log(user.ascview_usuario.ativo)
      if ((user.ascview_usuario && user.ascview_usuario.ativo !== 'Y')) {
        toast.error("Você não possui permissão para acessar essa página! Estamos lhe redirecionando.");
        setTimeout(() => {
          history.push(`/login`); // Redirecionar para a página de tickets ou outra página adequada
        }, 1000);
      }else if (!user.ascview_usuario){
        toast.error("Você não possui permissão para acessar essa página! Estamos lhe redirecionando.");
        setTimeout(() => {
          history.push(`/login`); // Redirecionar para a página de tickets ou outra página adequada
        }, 1000);
      }
    }
    
  }, [user, history]);
  useEffect(() => {
    const fetchFolders = async () => {
      try {
        setLoading(true);
        
        console.log("fetchFolders");
        const response = await fetchPastas(parentIdTrat, user);
        // // setPastas(response)
        // console.log(response)
        
        // const filteredFolders = response.filter(folder => folder.tipo !== 'pai');
        // setFolders(filteredFolders);
        // setDepartmentsDataOrdered(filteredFolders);
        
        // const parentFolder = response.find(folder => folder.tipo === 'pai');
        // setParentDepartmentName(parentFolder ? parentFolder.nome : "Departamentos");
      
      } catch (error) {
        console.error('Erro ao carregar pastas:', error);
      } finally {
        setLoading(false);
      }
    };
    console.log('Alterou o fetchFOLDERS')
    fetchFolders();
    
  }, [parentIdTrat, user]); 

  useEffect(() => {
    // console.log(pastas)
    const filteredFolders = pastas ? pastas.filter(folder => folder.tipo !== 'pai') : [];
    setFolders(filteredFolders);
    setDepartmentsDataOrdered(filteredFolders);
  
    const parentFolder = pastas ? pastas.find(folder => folder.tipo === 'pai') : null;
    setParentDepartmentName(parentFolder ? parentFolder.nome : "Departamentos");
  }, [pastas]); 
  
  const comparingValues = (a, b, isAscending, isDate = false) => {
    if (isDate) {
      const dateA = new Date(a);
      const dateB = new Date(b);
      return isAscending ? dateA - dateB : dateB - dateA;
    } else {
      return isAscending ? a.localeCompare(b) : b.localeCompare(a);
    }
  };

  const handleSortByName = () => {
    const sortedNames = [...folders].sort((a, b) => comparingValues(a.nome, b.nome, isAscending));
    setDepartmentsDataOrdered(sortedNames);
    setIsAscending(!isAscending);
    setCurrentIcon(isAscending ? <ArrowUpwardIcon /> : <ArrowDownwardIcon />);
  };

  const handleFilterClick = (filterType) => {
    setSelectedFilter(filterType);
  };

  const getButtonStyle = (filterType) => {
    return selectedFilter === filterType ? { backgroundColor: "#ccc" } : {};
  };

  const handleNavigateToFolder = (folderId) => {
    history.push(`/departments/${folderId}`);
  };


  const handleNavigateToReport = (folderId, workspaceId,reportId) => {
    history.push(`/departments/${folderId}/groups/${workspaceId}/reports/${reportId}`);
  };

  const handleSortByType = () => {
    const sortedByType = [...folders].sort((a, b) => comparingValues(a.tipo, b.tipo, isAscending));
    setDepartmentsDataOrdered(sortedByType);
    setIsAscending(!isAscending);
    setCurrentIcon(isAscending ? <ArrowUpwardIcon /> : <ArrowDownwardIcon />);
  };
  

  /*CONFIGURAÇÃO LISTAGEM DE RELATÓRIOS E WORKSPACE*/
  
  

  useEffect(() => {
    loadCredenciais();
}, []); // Chama apenas uma vez ao montar o componente

useEffect(() => {
    // Chama getTokenEmbeded apenas se clientId estiver presente
    if (config.clientId) {
      getTokenRequestHeader();
    }
}, [config.clientId]); // Dependência específica para clientId

const loadCredenciais = async () => {
    try {
        setLoading(true);
        const { data } = await api.get(`/credenciais/${companyId}`);
        setConfig(prevConfig => ({
            ...prevConfig,
            ...data
        }));
    } catch (err) {
        console.error('Erro ao carregar as credenciais:', err);
    } finally {
        setLoading(false);
    }
};

const getTokenRequestHeader = async () => {
    try {
        // Atualizar o workspaceId no objeto config

        const response = await api.post(`/getTokenRequestHeader/`, {
            body: config
        });

        setTokenApiPowerBi(response.data)
        console.log(tokenApiPowerBi)
        // setEmbedData(response.data);
        // console.log(response);
    } catch (error) {
        console.error('Erro ao obter o token:', error);
    }
};


const [delay, setDelay] = React.useState(true);

useEffect(() => {
  if (!loading) {
    const timer = setTimeout(() => setDelay(false), 1500);
    return () => clearTimeout(timer);
  } else {
    setDelay(true);
  }
}, [loading]);

if (loading || delay) {
  return (
    <div style={{
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      height: '100vh'
    }}>
      <img style={{
        width:'350px'
      }} src={require('./loading_asc_connecta.gif')} alt="Loading" />
    </div>
  );
}


  return (
    <div>
      <HeaderDepartment currentDepartmentData={{ id: departmentId, name: parentDepartmentName, parentId: parentIdTrat, token: tokenApiPowerBi }} />

      <TableContainer>
        <Table className={classes.table} aria-label="department table">
          <TableHead>
            <TableRow>
              <StyledTableCell><InsertDriveFileIcon color="action" /></StyledTableCell>
              <StyledTableCell>
                <Button
                  style={getButtonStyle("titulo")}
                  endIcon={currentIcon}
                  onClick={handleSortByName}
                >
                  Nome
                </Button>
              </StyledTableCell>
              <StyledTableCell>
                <Button
                  style={getButtonStyle("tipo")}
                  endIcon={currentIcon}
                  onClick={handleSortByType}
                >
                  Tipo
                </Button>
              </StyledTableCell>
              <StyledTableCell></StyledTableCell>
              <StyledTableCell>Ação</StyledTableCell>
            </TableRow>
          </TableHead>

          <TableBody>
            {departmentsDataOrdered.map((folder) => (
              <StyledTableRow 
              key={folder.id.toString()+folder.tipo} 
              onMouseEnter={() => setHoveredRowId(folder.id.toString()+folder.tipo)}
              onMouseLeave={() => {
                setHoveredRowId(null)
                setOpenEditAndDeleteButtons(false)
              }}>
                <StyledTableCell>
                  {folder.tipo === 'relatorio' ? (
                     <img style={{
                      width:'25px'
                    }} src={Barchart} alt="Gráfico" />
                  ) : (
                    <FolderIcon style={{ color: '#ffd968' }} />
                  )}
                </StyledTableCell>
                <StyledTableCell
                  className={classes.pointerCell}
                  onClick={() => folder.tipo === 'relatorio'
                    ? handleNavigateToReport(folder.pasta_pai_id,folder.workspaceId, folder.reportId)
                    : handleNavigateToFolder(folder.id)}
                >
                  {folder.nome}
                </StyledTableCell>

                <StyledTableCell>{folder.tipo === 'relatorio' ? 'Relatório' : 'Pasta'}</StyledTableCell> {/* Exibe o tipo */}
          
                {(user.ascview_usuario && user.ascview_usuario.ativo == 'Y' && user.ascview_usuario.profile == 'admin' && (
                  <StyledTableCell>

                  <EditOrDeleteRow
                    rowIdCell={folder.id.toString()+folder.tipo}
                    folderName={folder.nome}
                    hoveredRowId={hoveredRowId}
                    openEditAndDeleteButtons={openEditAndDeleteButtons}
                    setOpenEditAndDeleteButtons={setOpenEditAndDeleteButtons}
                    fileType={folder.tipo}
                    object={folder}
                    departmentsDatabase={departmentsDataOrdered}
                    setDepartmentsDatabase={setDepartmentsDataOrdered}
                  />

                </StyledTableCell>))}

                <StyledTableCell>
                  <Button onClick={() => folder.tipo === 'relatorio'
                    ? handleNavigateToReport(folder.pasta_pai_id,folder.workspaceId, folder.reportId)
                    : handleNavigateToFolder(folder.id)}>
                    Ver {folder.tipo === 'relatorio' ? 'Relatório' : 'Pastas'}
                  </Button>
                </StyledTableCell>
              </StyledTableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
};

export default DepartmentDetails;
