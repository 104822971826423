import React, { useContext, useEffect, useReducer, useState } from "react";
import { Link as RouterLink, useHistory } from "react-router-dom";

import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import ListSubheader from "@material-ui/core/ListSubheader";
import Divider from "@material-ui/core/Divider";
import { Badge, Collapse, List } from "@material-ui/core";
import DashboardOutlinedIcon from "@material-ui/icons/DashboardOutlined";
import WhatsAppIcon from "@material-ui/icons/WhatsApp";
import SyncAltIcon from "@material-ui/icons/SyncAlt";
import SettingsOutlinedIcon from "@material-ui/icons/SettingsOutlined";
import PeopleAltOutlinedIcon from "@material-ui/icons/PeopleAltOutlined";
import ContactPhoneOutlinedIcon from "@material-ui/icons/ContactPhoneOutlined";
import AccountTreeOutlinedIcon from "@material-ui/icons/AccountTreeOutlined";
import FlashOnIcon from "@material-ui/icons/FlashOn";
import HelpOutlineIcon from "@material-ui/icons/HelpOutline";
import CodeRoundedIcon from "@material-ui/icons/CodeRounded";
import EventIcon from "@material-ui/icons/Event";
import LocalOfferIcon from "@material-ui/icons/LocalOffer";
import EventAvailableIcon from "@material-ui/icons/EventAvailable";
import ExpandLessIcon from "@material-ui/icons/ExpandLess";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import PeopleIcon from "@material-ui/icons/People";
import ListIcon from "@material-ui/icons/ListAlt";
import AnnouncementIcon from "@material-ui/icons/Announcement";
import ForumIcon from "@material-ui/icons/Forum";
import LocalAtmIcon from '@material-ui/icons/LocalAtm';
import RotateRight from "@material-ui/icons/RotateRight";
import { i18n } from "../translate/i18n";
import { WhatsAppsContext } from "../context/WhatsApp/WhatsAppsContext";
import { AuthContext } from "../context/Auth/AuthContext";
import LoyaltyRoundedIcon from '@material-ui/icons/LoyaltyRounded';
import { Can } from "../components/Can";
import { socketConnection } from "../services/socket";
import { isArray } from "lodash";
import TableChartIcon from '@material-ui/icons/TableChart';
import api from "../services/api";
import BorderColorIcon from '@material-ui/icons/BorderColor';
import ToDoList from "../pages/ToDoList/";
import toastError from "../errors/toastError";
import { makeStyles } from "@material-ui/core/styles";
import { AllInclusive, AttachFile, BarChart, BarChartOutlined, BlurCircular, ChatBubbleOutlineRounded, ChatBubbleSharp, DeviceHubOutlined, InsertChart, Schedule, TableChart, TableChartSharp } from '@material-ui/icons';
import usePlans from "../hooks/usePlans";
import Typography from "@material-ui/core/Typography";
import { TbHome, TbLayoutKanban } from "react-icons/tb";
import { FaHome, FaNetworkWired } from "react-icons/fa";
import { IoChatbubbleOutline } from "react-icons/io5";
import { IoChatbubblesOutline } from "react-icons/io5";
import { RxDashboard } from "react-icons/rx";
import { FaHouseChimneyUser, FaWhatsapp } from "react-icons/fa6";
import { MdOutlineViewKanban } from "react-icons/md";
import { BsSpeedometer2 } from "react-icons/bs";
import { BiTask } from "react-icons/bi";
import { MdOutlineContactPhone } from "react-icons/md";
import { IoCalendarOutline } from "react-icons/io5";
import { PiCalendarCheckLight } from "react-icons/pi";
import { LuClipboardList } from "react-icons/lu";
import { TiContacts } from "react-icons/ti";
import { IoSettingsOutline } from "react-icons/io5";
import { BsTags } from "react-icons/bs";
import { SiInternetcomputer } from "react-icons/si";
import { GrNetwork } from "react-icons/gr";
import { IoFileTrayStackedOutline } from "react-icons/io5";
import { LuUsers } from "react-icons/lu";
import { useTheme } from '@mui/material/styles';
import { ListItemButton } from "@mui/material";
import { Link } from "react-router-dom/cjs/react-router-dom";
import { RiHome2Line } from "react-icons/ri";

const useStyles = makeStyles((theme) => ({
  ListSubheader: {
    height: 26,
    marginTop: "-15px",
    marginBottom: "-10px",
  },
  navbarlabel: {
    color: theme.palette.labelnavbar
  },
  // animationListItem:{
  //   transition: 'transform 0.3s ease-in-out',
  //         '&:hover': {
  //           transform: 'translateX(10px)',
  //         },
  // }
  linkColor: {
    color: "red"
  }
}));

const reducer = (state, action) => {
  if (action.type === "LOAD_CHATS") {
    const chats = action.payload;
    const newChats = [];

    if (isArray(chats)) {
      chats.forEach((chat) => {
        const chatIndex = state.findIndex((u) => u.id === chat.id);
        if (chatIndex !== -1) {
          state[chatIndex] = chat;
        } else {
          newChats.push(chat);
        }
      });
    }

    return [...state, ...newChats];
  }

  if (action.type === "UPDATE_CHATS") {
    const chat = action.payload;
    const chatIndex = state.findIndex((u) => u.id === chat.id);

    if (chatIndex !== -1) {
      state[chatIndex] = chat;
      return [...state];
    } else {
      return [chat, ...state];
    }
  }

  if (action.type === "DELETE_CHAT") {
    const chatId = action.payload;

    const chatIndex = state.findIndex((u) => u.id === chatId);
    if (chatIndex !== -1) {
      state.splice(chatIndex, 1);
    }
    return [...state];
  }

  if (action.type === "RESET") {
    return [];
  }

  if (action.type === "CHANGE_CHAT") {
    const changedChats = state.map((chat) => {
      if (chat.id === action.payload.chat.id) {
        return action.payload.chat;
      }
      return chat;
    });
    return changedChats;
  }
};

const MainListItems = (props) => {
  const classes = useStyles();
  const { drawerClose, collapsed } = props;
  const { whatsApps } = useContext(WhatsAppsContext);
  const { user, handleLogout } = useContext(AuthContext);
  const [connectionWarning, setConnectionWarning] = useState(false);
  const [showCampaigns, setShowCampaigns] = useState(false);
  const [showEmbedded, setShowEmbedded] = useState(false);
  const [showKanban, setShowKanban] = useState(false);
  const [showOpenAi, setShowOpenAi] = useState(false);
  const [showIntegrations, setShowIntegrations] = useState(false); const history = useHistory();
  const [showSchedules, setShowSchedules] = useState(false);
  const [showInternalChat, setShowInternalChat] = useState(false);
  const [showExternalApi, setShowExternalApi] = useState(false);

  const theme = useTheme();

  function ListItemLink(props) {
    const { icon, primary, to, className } = props;

    const renderLink = React.useMemo(
      () =>
        React.forwardRef((itemProps, ref) => (
          <RouterLink to={to} ref={ref} {...itemProps} onClick={drawerClose} />
        )),
      [to]
    );

    return (
      <li>
        <ListItem button dense component={renderLink} className={className}>
          {icon ? <ListItemIcon>{icon}</ListItemIcon> : null}
          <ListItemText primary={primary} />
        </ListItem>
      </li>
    );
  }


  const [openCampaignSubmenu, setOpenCampaignSubmenu] = useState(false);
  const [openAscChat, setopenAscChat] = useState(false);
  const [openAscView, setOpenAscView] = useState(false);
  const [openAdministratorSubMenu, setOpenAdministratorSubMenu] = useState(false);



  const [invisible, setInvisible] = useState(true);
  const [pageNumber, setPageNumber] = useState(1);
  const [searchParam] = useState("");
  const [chats, dispatch] = useReducer(reducer, []);
  const { getPlanCompany } = usePlans();


  useEffect(() => {
    dispatch({ type: "RESET" });
    setPageNumber(1);
  }, [searchParam]);

  useEffect(() => {
    async function fetchData() {
      const companyId = user.companyId;
      const planConfigs = await getPlanCompany(undefined, companyId);

      // console.log(planConfigs) /*remover*/
      setShowCampaigns(planConfigs.plan.useCampaigns);
      setShowEmbedded(planConfigs.plan.useEmbedded);
      setShowKanban(planConfigs.plan.useKanban);
      setShowOpenAi(planConfigs.plan.useOpenAi);
      setShowIntegrations(planConfigs.plan.useIntegrations);
      setShowSchedules(planConfigs.plan.useSchedules);
      setShowInternalChat(planConfigs.plan.useInternalChat);
      setShowExternalApi(planConfigs.plan.useExternalApi);
    }
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);



  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      fetchChats();
    }, 500);
    return () => clearTimeout(delayDebounceFn);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchParam, pageNumber]);

  useEffect(() => {
    const companyId = localStorage.getItem("companyId");
    const socket = socketConnection({ companyId });

    socket.on(`company-${companyId}-chat`, (data) => {
      if (data.action === "new-message") {
        dispatch({ type: "CHANGE_CHAT", payload: data });
      }
      if (data.action === "update") {
        dispatch({ type: "CHANGE_CHAT", payload: data });
      }
    });
    return () => {
      socket.disconnect();
    };
  }, []);

  useEffect(() => {
    let unreadsCount = 0;
    if (chats.length > 0) {
      for (let chat of chats) {
        for (let chatUser of chat.users) {
          if (chatUser.userId === user.id) {
            unreadsCount += chatUser.unreads;
          }
        }
      }
    }
    if (unreadsCount > 0) {
      setInvisible(false);
    } else {
      setInvisible(true);
    }
  }, [chats, user.id]);

  useEffect(() => {
    if (localStorage.getItem("cshow")) {
      setShowCampaigns(true);
    }
  }, []);

  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      if (whatsApps.length > 0) {
        const offlineWhats = whatsApps.filter((whats) => {
          return (
            whats.status === "qrcode" ||
            whats.status === "PAIRING" ||
            whats.status === "DISCONNECTED" ||
            whats.status === "TIMEOUT" ||
            whats.status === "OPENING"
          );
        });
        if (offlineWhats.length > 0) {
          setConnectionWarning(true);
        } else {
          setConnectionWarning(false);
        }
      }
    }, 2000);
    return () => clearTimeout(delayDebounceFn);
  }, [whatsApps]);

  const fetchChats = async () => {
    try {
      const { data } = await api.get("/chats/", {
        params: { searchParam, pageNumber },
      });
      dispatch({ type: "LOAD_CHATS", payload: data.records });
    } catch (err) {
      toastError(err);
    }
  };

  const handleClickLogout = () => {
    //handleCloseMenu();
    handleLogout();
  };

  const [openPaineis, setOpenPaineis] = useState(false);  // Controla o subnível Paineis

  return (
    <div >
      {/* SISTEMA  */}
      <ListItemButton
        component={Link}
        to="/"
        style={{ textDecoration: 'none', display: 'flex', justifyContent: 'space-between', gap: "20px" }} // Remove o sublinhado do link
      >
        <div>
          <TbHome
            style={{
              fontSize: 'clamp(10px, 25px, 25px)',
              color: theme.palette.mode === 'light' ? '#3CA6A6' : '#FFFFFF' // Usando a cor baseada no tema
            }}
          />
        </div>
        <ListItemText
          primary="Home"
          primaryTypographyProps={{
            style: {
              color: theme.palette.labelnavbar,
              marginLeft: '10px'
            }
          }} // Muda a cor do texto com base no tema
        />
      </ListItemButton>


      {/* ASCVIEW */}

      {(user.profile === 'admin' || (user.ascview_usuario && user.ascview_usuario.ativo === 'Y')) && (
        <>
          {showEmbedded && (
            <ListItem button onClick={() => setOpenAscView((prev) => !prev)} style={{ textDecoration: 'none', display: 'flex', justifyContent: 'space-between' }}>
              <ListItemIcon>
                <TbLayoutKanban style={{ fontSize: 'clamp(10px, 25px, 25px)', color: '#3CA6A6' }} />
              </ListItemIcon>
              <ListItemText primary="AscView" style={{ color: theme.palette.labelnavbar }} />
              {openAscView ? <ExpandLessIcon style={{ color: '#3CA6A6' }} /> : <ExpandMoreIcon style={{ color: '#3CA6A6' }} />}
            </ListItem>)}

          {/* <Collapse style={{ paddingLeft: 15 }} in={openAscView} timeout="auto" unmountOnExit> */}
          {/* Subnível Paineis */}
          {/* <ListItem button onClick={() => setOpenPaineis((prev) => !prev)}>
            <ListItemIcon>
              <BarChartOutlined />
            </ListItemIcon>
            <ListItemText primary="Departamentos" />
            {openPaineis ? <ExpandLessIcon /> : <ExpandMoreIcon />}
          </ListItem> */}

          <Collapse style={{ paddingLeft: 10 }} in={openAscView} timeout="auto" unmountOnExit>

            <ListItemButton
              component={Link}
              to="/ascview"
              style={{ textDecoration: 'none', display: "flex", justifyContent: "space-between" }} // Remove o sublinhado do link
            >
              <div>
              <RiHome2Line
                style={{
                  fontSize: 'clamp(10px, 25px, 25px)',
                  color: theme.palette.mode === 'light' ? '#3CA6A6' : '#FFFFFF' // Usando a cor baseada no tema
                }}
              />
              </div>
              <ListItemText
                primary="Visão Geral"
                primaryTypographyProps={{ style: { color: theme.palette.labelnavbar, marginLeft: '12px' } }} // Muda a cor do texto
              />
            </ListItemButton>

            <ListItemButton
              component={Link}
              to="/departments"
              style={{ textDecoration: 'none', display: "flex", justifyContent: "space-between" }} // Remove o sublinhado do link
            >
              <div>
                <FaNetworkWired
                  style={{
                    fontSize: 'clamp(10px, 25px, 25px)',
                    color: '#3CA6A6' // Usando a cor primária do tema
                  }}
                />
              </div>
              <ListItemText
                primary="Departamentos"
                primaryTypographyProps={{ style: { color: theme.palette.labelnavbar, marginLeft: '12px' } }} // Muda a cor do texto
              />
            </ListItemButton>

            {(user.profile == 'admin' || (user.ascview_usuario && user.ascview_usuario.profile == "admin" && user.ascview_usuario.ativo == 'Y')) && (
              <>
                <ListItemButton
                  component={Link}
                  to="/departments-settings"
                  style={{ textDecoration: 'none', display: 'flex', justifyContent: 'space-between' }} // Remove o sublinhado do link
                >
                  <div>
                    <IoSettingsOutline
                      style={{
                        fontSize: 'clamp(10px, 25px, 25px)',
                        color: '#3CA6A6' // Usando a cor primária do tema
                      }}
                    />
                  </div>
                  <ListItemText
                    primary="Configurações"
                    primaryTypographyProps={{ style: { color: theme.palette.labelnavbar, marginLeft: '12px' } }} // Muda a cor do texto
                  />
                </ListItemButton>

                <ListItemButton
                  component={Link}
                  to="/departments-users"
                  style={{ textDecoration: 'none', display: 'flex', justifyContent: 'space-between' }} // Remove o sublinhado do link
                >
                  <PeopleAltOutlinedIcon
                    style={{
                      fontSize: 'clamp(10px, 25px, 25px)',
                      color: '#3CA6A6' // Usando a cor primária do tema
                    }}
                  />
                  <ListItemText
                    primary="Usuários"
                    primaryTypographyProps={{ style: { color: theme.palette.labelnavbar, marginLeft: '12px' } }} // Muda a cor do texto
                  />
                </ListItemButton>
              </>
            )}



          </Collapse>
        </>

      )}
      {/* </Collapse> */}


      <ListItem
        button
        onClick={() => setopenAscChat((prev) => !prev)}
      >
        <ListItemIcon>
          <IoChatbubbleOutline style={{ fontSize: 'clamp(10px, 25px, 25px)', color: '#3CA6A6' }} />
        </ListItemIcon>
        <ListItemText
          primary={"AscChat"}
          style={{ color: theme.palette.labelnavbar }}
        />
        {openAscChat ? (
          <ExpandLessIcon style={{ color: '#3CA6A6' }} />
        ) : (
          <ExpandMoreIcon style={{ color: '#3CA6A6' }} />
        )}
      </ListItem>
      <Collapse
        style={{ paddingLeft: 15 }}
        in={openAscChat}
        timeout="auto"
        unmountOnExit
      >

        <Can
          role={user.profile}
          perform="dashboard:view"
          yes={() => (
            <ListItemButton
              component={Link}
              to="/dashboard"
              style={{ textDecoration: 'none', display: 'flex', justifyContent: 'space-between' }} // Remove o sublinhado do link
            >
              <div>
                <RxDashboard
                  style={{
                    fontSize: 'clamp(10px, 25px, 25px)',
                    color: '#3CA6A6' // Usando a cor primária do tema
                  }}
                />
              </div>
              <ListItemText
                primary="Dashboard"
                primaryTypographyProps={{ style: { color: theme.palette.labelnavbar, marginLeft: '10px' } }} // Muda a cor do texto
              />
            </ListItemButton>
          )}
        />

        <ListItemButton
          component={Link}
          to="/tickets"
          style={{ textDecoration: 'none', display: 'flex', justifyContent: 'space-between' }} // Remove o sublinhado do link
        >
          <div>
            <FaWhatsapp
              style={{ fontSize: 'clamp(10px, 25px, 25px)', color: '#3CA6A6' }} // Usando a cor primária do tema
            />
          </div>
          <ListItemText
            primary={i18n.t("mainDrawer.listItems.tickets")}
            primaryTypographyProps={{ style: { color: theme.palette.labelnavbar, marginLeft: '10px' } }} // Muda a cor do texto
          />
        </ListItemButton>

        <ListItemButton
          component={Link}
          to="/kanban"
          style={{ textDecoration: 'none', display: 'flex', justifyContent: 'space-between' }} // Remove o sublinhado do link
        >
          <div>
            <MdOutlineViewKanban
              style={{ fontSize: 'clamp(10px, 25px, 25px)', color: '#3CA6A6' }} // Usando a cor primária do tema
            />
          </div>
          <ListItemText
            primary={i18n.t("Kanban")}
            primaryTypographyProps={{ style: { color: theme.palette.labelnavbar, marginLeft: '10px' } }} // Muda a cor do texto
          />
        </ListItemButton>

        <ListItemButton
          component={Link}
          to="/quick-messages"
          style={{ textDecoration: 'none', display: 'flex', justifyContent: 'space-between' }} // Remove o sublinhado do link
        >
          <div>
            <BsSpeedometer2
              style={{ fontSize: 'clamp(10px, 25px, 25px)', color: '#3CA6A6' }} // Usando a cor primária do tema
            />
          </div>
          <ListItemText
            primary={i18n.t("mainDrawer.listItems.quickMessages")}
            primaryTypographyProps={{ style: { color: theme.palette.labelnavbar, marginLeft: '10px' } }} // Muda a cor do texto
          />
        </ListItemButton>

        <ListItemButton
          component={Link}
          to="/todolist"
          style={{ textDecoration: 'none', display: 'flex', justifyContent: 'space-between' }} // Remove o sublinhado do link
        >
          <div>
            <BiTask
              style={{ fontSize: 'clamp(10px, 25px, 25px)', color: '#3CA6A6' }} // Usando a cor primária do tema
            />
          </div>
          <ListItemText
            primary={i18n.t("Tarefas")}
            primaryTypographyProps={{ style: { color: theme.palette.labelnavbar, marginLeft: '10px' } }} // Muda a cor do texto
          />
        </ListItemButton>

        <ListItemButton
          component={Link}
          to="/contacts"
          style={{ textDecoration: 'none', display: 'flex', justifyContent: 'space-between' }} // Remove o sublinhado do link
        >
          <div>
            <MdOutlineContactPhone
              style={{ fontSize: 'clamp(10px, 25px, 25px)', color: '#3CA6A6' }} // Usando a cor primária do tema
            />
          </div>
          <ListItemText
            primary={i18n.t("mainDrawer.listItems.contacts")}
            primaryTypographyProps={{ style: { color: theme.palette.labelnavbar, marginLeft: '10px' } }} // Muda a cor do texto
          />
        </ListItemButton>

        <ListItemButton
          component={Link}
          to="/schedules"
          style={{ textDecoration: 'none', display: 'flex', justifyContent: 'space-between' }} // Remove o sublinhado do link
        >
          <div>
            <IoCalendarOutline
              style={{ fontSize: 'clamp(10px, 25px, 25px)', color: '#3CA6A6' }} // Usando a cor primária do tema
            />
          </div>
          <ListItemText
            primary={i18n.t("mainDrawer.listItems.schedules")}
            primaryTypographyProps={{ style: { color: theme.palette.labelnavbar, marginLeft: '10px' } }} // Muda a cor do texto
          />
        </ListItemButton>

      </Collapse>


      <ListItemButton
        component={Link}
        to="/chats"
        style={{ textDecoration: 'none', display: 'flex', justifyContent: 'space-between', gap: '20px' }} // Remove o sublinhado do link
      >
        <Badge color="secondary" variant="dot" invisible={invisible}>
          <IoChatbubblesOutline
            style={{ fontSize: 'clamp(10px, 25px, 25px)', color: theme.palette.mode === 'light' ? '#3CA6A6' : '#FFFFFF' }} // Usando a cor baseada no tema
          />
        </Badge>
        <ListItemText
          primary={i18n.t("mainDrawer.listItems.chats")}
          primaryTypographyProps={{ style: { color: theme.palette.labelnavbar, marginLeft: '10px' } }} // Muda a cor do texto com base no tema
        />
      </ListItemButton>

      <ListItemButton
        component={Link}
        to="/helps"
        style={{ textDecoration: 'none', display: 'flex', justifyContent: 'space-between', gap: '20px' }} // Remove o sublinhado do link
      >
        <HelpOutlineIcon
          style={{ fontSize: 'clamp(10px, 25px, 25px)', color: '#3CA6A6' }} // Usando a cor baseada no tema
        />
        <ListItemText
          primary={i18n.t("mainDrawer.listItems.helps")}
          primaryTypographyProps={{ style: { color: theme.palette.labelnavbar, marginLeft: '10px' } }} // Muda a cor do texto com base no tema
        />
      </ListItemButton>


      <Can
        role={user.profile}
        perform="drawer-admin-items:view"
        yes={() => (
          <>
            <Divider />

            {/* <ListSubheader
              hidden={collapsed}
              style={{
                position: "relative",
                fontSize: "17px",
                textAlign: "left",
                paddingLeft: 20
              }}
              inset
              color="inherit">
              {i18n.t("mainDrawer.listItems.administration")}
            </ListSubheader> */}

            <ListSubheader
              hidden={collapsed}
              style={{
                position: "relative",
                fontSize: "17px",
                textAlign: "left",
                paddingLeft: 20,
                display: "flex",
                justifyContent: "space-between", // Para separar o título e a seta
                alignItems: "center", // Alinha verticalmente
                cursor: 'pointer',
              }}
              inset
              color="inherit"
              onClick={() => setOpenAdministratorSubMenu((prev) => !prev)}
            >
              {/* {i18n.t("mainDrawer.listItems.administration")} */}
              <ListItemText
                primary={collapsed ? i18n.t("mainDrawer.listItems.administration").slice(0,3) : i18n.t("mainDrawer.listItems.administration")} 
              />

              {!collapsed && (
                <ListItemIcon style={{ justifyContent: 'flex-end' }}>
                  {openAdministratorSubMenu ? (
                    <ExpandLessIcon style={{ color: theme.palette.labelnavbar, height: '25px' }} />
                  ) : (
                    <ExpandMoreIcon style={{ color: theme.palette.labelnavbar, height: '25px' }} />
                  )}
                </ListItemIcon>
              )}
            </ListSubheader>

            <Collapse
              style={{ paddingLeft: 15 }}
              in={openAdministratorSubMenu}
              timeout="auto"
              unmountOnExit
            >

              {showCampaigns && (
                <>
                  <ListItem
                    button
                    onClick={() => setOpenCampaignSubmenu((prev) => !prev)}
                  >
                    <ListItemIcon>
                      <PiCalendarCheckLight style={{ fontSize: 'clamp(10px, 25px, 25px)', color: '#3CA6A6' }} />
                    </ListItemIcon>
                    <ListItemText
                      primary={i18n.t("mainDrawer.listItems.campaigns")}
                      style={{ color: theme.palette.labelnavbar }}
                    />
                    {openCampaignSubmenu ? (
                      <ExpandLessIcon style={{ color: '#3CA6A6' }} />
                    ) : (
                      <ExpandMoreIcon style={{ color: '#3CA6A6' }} />
                    )}
                  </ListItem>
                  <Collapse
                    style={{ paddingLeft: 15 }}
                    in={openCampaignSubmenu}
                    timeout="auto"
                    unmountOnExit
                  >
                    <List component="div" disablePadding>
                      <ListItem onClick={() => history.push("/campaigns")} button>
                        <ListItemIcon>
                          <LuClipboardList style={{ fontSize: 'clamp(10px, 25px, 25px)', color: '#3CA6A6' }} />
                        </ListItemIcon>
                        <ListItemText primary="Listagem" style={{ color: theme.palette.labelnavbar }} />
                      </ListItem>
                      <ListItem
                        onClick={() => history.push("/contact-lists")}
                        button
                      >
                        <ListItemIcon>
                          <TiContacts style={{ fontSize: 'clamp(10px, 25px, 25px)', color: '#3CA6A6' }} />
                        </ListItemIcon>
                        <ListItemText primary="Listas de Contatos" style={{ color: theme.palette.labelnavbar }} />
                      </ListItem>
                      <ListItem
                        onClick={() => history.push("/campaigns-config")}
                        button
                      >
                        <ListItemIcon>
                          <IoSettingsOutline style={{ fontSize: 'clamp(10px, 25px, 25px)', color: '#3CA6A6' }} />
                        </ListItemIcon>
                        <ListItemText primary="Configurações" style={{ color: theme.palette.labelnavbar }} />
                      </ListItem>
                    </List>
                  </Collapse>
                </>
              )}



              <ListItemButton
                component={Link}
                to="/tags"
                style={{ textDecoration: 'none', display: 'flex', justifyContent: 'space-between', gap: "20px" }} // Remove o sublinhado do link
              >
                <div>
                  <BsTags
                    style={{
                      fontSize: 'clamp(10px, 25px, 25px)',
                      color: theme.palette.mode === 'light' ? '#3CA6A6' : '#FFFFFF'
                    }}
                  />
                </div>
                <ListItemText
                  primary={i18n.t("mainDrawer.listItems.tags")}
                  primaryTypographyProps={{
                    style: {
                      color: theme.palette.labelnavbar,
                      marginLeft: '10px'
                    }
                  }}
                />
              </ListItemButton>

              {user.super && (
                <ListItemButton
                  component={Link}
                  to="/announcements"
                  style={{ textDecoration: 'none', display: 'flex', justifyContent: 'space-between', gap: "20px" }} // Remove o sublinhado do link
                >
                  <AnnouncementIcon
                    style={{
                      fontSize: 'clamp(10px, 25px, 25px)',
                      color: theme.palette.mode === 'light' ? '#3CA6A6' : '#FFFFFF' // Usando a cor baseada no tema
                    }}
                  />
                  <ListItemText
                    primary={i18n.t("mainDrawer.listItems.annoucements")}
                    primaryTypographyProps={{
                      style: {
                        color: theme.palette.labelnavbar,
                        marginLeft: '10px'
                      }
                    }} // Muda a cor do texto com base no tema
                  />
                </ListItemButton>
              )}

              {showOpenAi && (
                <ListItemButton
                  component={Link}
                  to="/prompts"
                  style={{ textDecoration: 'none', display: 'flex', justifyContent: 'space-between', gap: "20px" }} // Remove o sublinhado do link
                >
                  <div>
                    <SiInternetcomputer
                      style={{
                        fontSize: 'clamp(10px, 25px, 25px)',
                        color: theme.palette.mode === 'light' ? '#3CA6A6' : '#FFFFFF' // Usando a cor baseada no tema
                      }}
                    />
                  </div>
                  <ListItemText
                    primary={i18n.t("mainDrawer.listItems.prompts")}
                    primaryTypographyProps={{
                      style: {
                        color: theme.palette.labelnavbar,
                        marginLeft: '10px'
                      }
                    }} // Muda a cor do texto com base no tema
                  />
                </ListItemButton>

              )}

              {showIntegrations && (
                <ListItemButton
                  component={Link}
                  to="/queue-integration"
                  style={{ textDecoration: 'none', display: 'flex', justifyContent: 'space-between', gap: "20px" }} // Remove o sublinhado do link
                >
                  <div>
                    <GrNetwork
                      style={{
                        fontSize: 'clamp(10px, 25px, 25px)',
                        color: theme.palette.mode === 'light' ? '#3CA6A6' : '#FFFFFF' // Usando a cor baseada no tema
                      }}
                    />
                  </div>
                  <ListItemText
                    primary={i18n.t("mainDrawer.listItems.queueIntegration")}
                    primaryTypographyProps={{
                      style: {
                        color: theme.palette.labelnavbar,
                        marginLeft: '10px'
                      }
                    }} // Muda a cor do texto com base no tema
                  />
                </ListItemButton>
              )}

              <ListItemButton
                component={Link}
                to="/connections"
                style={{ textDecoration: 'none', display: 'flex', justifyContent: 'space-between', gap: "20px" }} // Remove o sublinhado do link
              >
                <Badge badgeContent={connectionWarning ? "!" : 0} color="error">
                  <IoFileTrayStackedOutline
                    style={{
                      fontSize: 'clamp(10px, 25px, 25px)',
                      color: theme.palette.mode === 'light' ? '#3CA6A6' : '#FFFFFF' // Usando a cor baseada no tema
                    }}
                  />
                </Badge>
                <ListItemText
                  primary={i18n.t("mainDrawer.listItems.connections")}
                  primaryTypographyProps={{
                    style: {
                      color: theme.palette.labelnavbar,
                      marginLeft: '10px'
                    }
                  }} // Muda a cor do texto com base no tema
                />
              </ListItemButton>

              <ListItemButton
                component={Link}
                to="/files"
                style={{ textDecoration: 'none', display: 'flex', justifyContent: 'space-between', gap: "20px" }} // Remove o sublinhado do link
              >
                <AttachFile
                  style={{
                    fontSize: 'clamp(10px, 25px, 25px)',
                    color: theme.palette.mode === 'light' ? '#3CA6A6' : '#FFFFFF' // Usando a cor baseada no tema
                  }}
                />
                <ListItemText
                  primary={i18n.t("mainDrawer.listItems.files")}
                  primaryTypographyProps={{
                    style: {
                      color: theme.palette.labelnavbar,
                      marginLeft: '10px'
                    }
                  }} // Muda a cor do texto com base no tema
                />
              </ListItemButton>

              <ListItemButton
                component={Link}
                to="/queues"
                style={{ textDecoration: 'none', display: 'flex', justifyContent: 'space-between', gap: "20px" }} // Remove o sublinhado do link
              >
                <AccountTreeOutlinedIcon
                  style={{
                    fontSize: 'clamp(10px, 25px, 25px)',
                    color: theme.palette.mode === 'light' ? '#3CA6A6' : '#FFFFFF' // Usando a cor baseada no tema
                  }}
                />
                <ListItemText
                  primary={i18n.t("mainDrawer.listItems.queues")}
                  primaryTypographyProps={{
                    style: {
                      color: theme.palette.labelnavbar,
                      marginLeft: '10px'
                    }
                  }} // Muda a cor do texto com base no tema
                />
              </ListItemButton>

              <ListItemButton
                component={Link}
                to="/users"
                style={{ textDecoration: 'none', display: 'flex', justifyContent: 'space-between', gap: "20px" }} // Remove o sublinhado do link
              >
                <PeopleAltOutlinedIcon
                  style={{
                    fontSize: 'clamp(10px, 25px, 25px)',
                    color: theme.palette.mode === 'light' ? '#3CA6A6' : '#FFFFFF' // Usando a cor baseada no tema
                  }}
                />
                <ListItemText
                  primary={i18n.t("mainDrawer.listItems.users")}
                  primaryTypographyProps={{
                    style: {
                      color: theme.palette.labelnavbar,
                      marginLeft: '10px'
                    }
                  }} // Muda a cor do texto com base no tema
                />
              </ListItemButton>

              {showExternalApi && (
                <>
                  <ListItemButton
                    component={Link}
                    to="/messages-api"
                    style={{ textDecoration: 'none', display: 'flex', justifyContent: 'space-between', gap: "20px" }} // Remove o sublinhado do link
                  >
                    <CodeRoundedIcon
                      style={{
                        fontSize: 'clamp(10px, 25px, 25px)',
                        color: theme.palette.mode === 'light' ? '#3CA6A6' : '#FFFFFF' // Usando a cor baseada no tema
                      }}
                    />
                    <ListItemText
                      primary={i18n.t("mainDrawer.listItems.messagesAPI")}
                      primaryTypographyProps={{
                        style: {
                          color: theme.palette.labelnavbar,
                          marginLeft: '10px'
                        }
                      }} // Muda a cor do texto com base no tema
                    />
                  </ListItemButton>

                </>
              )}

              <ListItemButton
                component={Link}
                to="/financeiro"
                style={{ textDecoration: 'none', display: 'flex', justifyContent: 'space-between', gap: "20px" }} // Remove o sublinhado do link
              >
                <div>
                  <LuUsers
                    style={{
                      fontSize: 'clamp(10px, 25px, 25px)',
                      color: theme.palette.mode === 'light' ? '#3CA6A6' : '#FFFFFF' // Usando a cor baseada no tema
                    }}
                  />
                </div>
                <ListItemText
                  primary={i18n.t("mainDrawer.listItems.financeiro")}
                  primaryTypographyProps={{
                    style: {
                      color: theme.palette.labelnavbar,
                      marginLeft: '10px'
                    }
                  }} // Muda a cor do texto com base no tema
                />
              </ListItemButton>

              <ListItemButton
                component={Link}
                to="/settings"
                style={{ textDecoration: 'none', display: 'flex', justifyContent: 'space-between', gap: "20px" }} // Remove o sublinhado do link
              >
                <div>
                  <IoSettingsOutline
                    style={{
                      fontSize: 'clamp(10px, 25px, 25px)',
                      color: theme.palette.mode === 'light' ? '#3CA6A6' : '#FFFFFF' // Usando a cor baseada no tema
                    }}
                  />
                </div>
                <ListItemText
                  primary={i18n.t("mainDrawer.listItems.settings")}
                  primaryTypographyProps={{
                    style: {
                      color: theme.palette.labelnavbar,
                      marginLeft: '10px'
                    }
                  }} // Muda a cor do texto com base no tema
                />
              </ListItemButton>



              {!collapsed && <React.Fragment>
                <Divider />
                {/* 
              // IMAGEM NO MENU
              <Hidden only={['sm', 'xs']}>
                <img style={{ width: "100%", padding: "10px" }} src={logo} alt="image" />            
              </Hidden> 
              */}

                <Typography style={{ fontSize: "12px", padding: "10px", textAlign: "right", fontWeight: "bold" }}>
                  Versão: 5.3.1
                </Typography>
              </React.Fragment>
              }
            </Collapse>
          </>
        )}
      />
    </div>
  );
};

export default MainListItems;